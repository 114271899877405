<template>
  <div id="contentdetail">
    <div class="brief">{{storyJson.brief_info}}</div>
    <!--
      <div v-if="copyright.length>0" style="color:rgb(180, 180, 180);font-size:0.1rem;padding-top:0.1rem;">版权 {{copyright}}</div>
    -->
    <div class='anchor'>
      <div><img class="anchorimage" v-bind:src="storyJson.author_image_url" alt=""></div>
      <div class="anchorname">{{storyJson.author_name}}</div>
    </div>
    <div v-if="detail_type==2"><img class="coverimage" v-bind:src="storyJson.cover_image_url" alt=""></div>
    <div class="playarea">
      <div><img class='playbutton' v-bind:src="playicon" @click="playAudio(0)" alt=""></div>
      <div class="progress">
        <van-slider class='sliderprogress' active-color='#95d938' button-size='0.1rem' v-model="progressvalue" @change="onProgressChange" 
        @drag-start="dragStart" @drag-end="dragEnd"/>
        <div class="textprogress">{{progressvaluestr}}</div>
      </div>
    </div>
    <div v-if="have_sub_album">
      <div class="brief" style="margin-top:0.2rem;">
        <span style="color:burlywood;font-weight:border;">专辑</span>
        &nbsp;&nbsp;{{storyJson.sub_album_data.title}}
        &nbsp;&nbsp;共{{storyJson.sub_album_data.story_num}}集
      </div>
      <div style="flex-direction: row;display: flex;position: relative;overflow: auto;">
        <div v-for="(item,index) in subAlbumStoryList" :key="index" @click="selectStory(index)">
          <div style="padding:0.1rem 0.05rem;">
            <div style="position: relative;">
              <img style="display:block;width:1.33rem;height:0.75rem;border-radius:0.1rem;" v-bind:src="item.cover_image_url" alt="">
              <div v-if="item.story_id==storyJson.story_id" style="position: absolute;top:0;bottom:0;left:0;right: 0;background:rgba(0,0,0,.5);z-index:9999;border-radius:0.1rem;">
                <div style="font-size:0.15rem;text-align:center;color:white;padding-top:0.3rem;">播放中...</div>
              </div>
            </div>
            <div class="brief" style="width:1.3rem;line-clamp:1">{{item.title}}</div>
          </div> 
        </div>
      </div>
    </div>

    <div v-if="stage<=1" class="salebutton" @click="buy">
      开通会员 查看文本
    </div>
    <div v-else class="detail_info">
      <div v-if="detail_type==1"><img class="detail_image" v-bind:src="detail_image_url"></div>
      <div v-if="detail_type==2" style="color:#222222;line-height: 0.2rem;" v-html="detail_text"></div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import {Howl} from 'howler'
import axios from 'axios'

export default {
  data() {
    return {
      userID: "",
      storyJson:{},
      currIdx:-1,

      os_type:'',
      stage:0,

      have_sub_album:false,
      subAlbumStoryList:[],

      playing:false,
      drag_progress:-100,
      gen: null,
      timer: null,
      copyright:"",

      detail_type:0,
      detail_text:'',
      detail_image_url:'',

      playicon: require('../assets/audio-icon-play.png'),
      progressvalue: 0,
      sound: null,
      touching: false,
      progressvaluestr:"00:00/00:00"
    };
  },
  created() {
    document.title = '通途英语'
    this.getRouterData()
  },
  mounted(){
    const that = this
    window.setPlayProgress = that.setPlayProgress
    window.autoPlay = that.autoPlay
    window.finish = that.finish
    window.refresh = that.refresh
  },
  deactivated() {
    //
  },
  beforeDestroy() {
    this.sound && this.sound.stop()
  },
  methods: {
    getRouterData() {
      console.log("route",window.location.href)
      let params = JSON.parse(this.$route.query.params);
      this.userID = params.userID
      this.storyJson = params.storyData
      if(window.location.href.includes('os_type=')) this.os_type=this.$route.query.os_type

      if(this.os_type=='ios') {
        //this.os_type=''
        window.webkit.messageHandlers.autoPlay.postMessage(null)
      }
      else {
        //this.os_type=''
        this.playAudio(1)
      }

      this.have_sub_album=false
      if(Object.prototype.hasOwnProperty.call(this.storyJson,'sub_album_data')){
        if(Object.prototype.hasOwnProperty.call(this.storyJson.sub_album_data,'copyright')){
          this.copyright = this.storyJson.sub_album_data.copyright
        }
        this.have_sub_album=true
        let request_data = {
          "type": "story_recommend",
          "data": {
            "user_id": this.userID,
            "record_list": [],
            "need_story_num": this.storyJson.sub_album_data.story_num,
            "content_scope": 2,
            'album_id':'S00D',
            'sub_album_id':this.storyJson.sub_album_id,
            "first_time": true,

            "stage_status": 1
          }
        };
        axios.post("https://caixiaowen.com/tongtu", JSON.stringify(request_data))
        .then((result) => {
          this.stage = result.data.stage
          console.log("stage",this.stage)

          this.subAlbumStoryList=result.data.story_list
          for(var i=0;i<this.subAlbumStoryList.length;i+=1){
            if(this.storyJson.story_id==this.subAlbumStoryList[i].story_id){
              this.currIdx = i
              break
            }
          }
        }).catch((error) => {
          console.log(error);
        });
      }
    },

    getDetail(){
      this.detail_type=this.storyJson.detail_info.type
      if(this.detail_type==2) {
        axios.get(this.storyJson.detail_info.url)
        .then((result) =>{
          this.detail_text=result.data
        }).catch((error) =>{
          console.log(error)
        })
      }
      else if(this.detail_type==1){
        this.detail_image_url = this.storyJson.detail_info.url
      }
    },

    selectStory(index){
      if(index>=this.subAlbumStoryList.length) return

      console.log('play new story',index)
      this.storyJson = this.subAlbumStoryList[index]
      this.currIdx=index
      this.playAudio(1)
    },

    getPosition() {
      var duration = this.sound.duration();
      if(duration>0) {
        var soundseek=this.sound.seek()
        this.progressvalue = soundseek*100/duration;

        var t1=parseInt(soundseek/60)
        if(t1<10)t1='0'+t1
        var t2=parseInt(soundseek%60)
        if(t2<10)t2='0'+t2
        var t3=parseInt(duration/60)
        if(t3<10)t3='0'+t3
        var t4=parseInt(duration%60)
        if(t4<10)t4='0'+t4
        this.progressvaluestr=''+t1+':'+t2+'/'+t3+':'+t4
      }

      var s = this.sound.playing()
      if(!s) {
        this.playicon=require('../assets/audio-icon-play.png');
      }
      if( s && !this.touching) {
        requestAnimationFrame(this.getPosition);
      }
    },
    genTime: function* () {
      let second = 0 
      while (true) {
        second += 1;
        yield second;
      }
    },

    refresh(){
      //
    },
    finish(){
      this.sound && this.sound.stop()
      this.sound=null
    },
    autoPlay(){
      this.playAudio(1)
    },

    setPlayProgress(json_str){
      let t = JSON.parse(json_str)

      if(this.timer!=null){
        clearInterval(this.timer)
        this.timer = null
        this.gen = null
      }

      this.playing = true
      this.playicon=require('../assets/audio-icon-pause.png')

      this.gen = this.genTime()
      this.timer = setInterval(() => {
        var soundseek=t.progress+this.gen.next().value
        var t1=parseInt(soundseek/60)
        if(t1<10)t1='0'+t1
        var t2=parseInt(soundseek%60)
        if(t2<10)t2='0'+t2
        var t3=parseInt(t.duration/60)
        if(t3<10)t3='0'+t3
        var t4=parseInt(t.duration%60)
        if(t4<10)t4='0'+t4

        if(!this.touching){
          this.progressvalue = soundseek*100/t.duration
          this.progressvaluestr=''+t1+':'+t2+'/'+t3+':'+t4
          if(this.progressvalue>100 || !this.playing){
            clearInterval(this.timer)
            this.timer = null
            this.gen = null

            this.playing = false
            this.playicon=require('../assets/audio-icon-play.png')
          }
        }
      }, 1000)

      // 在组件销毁的时候清空定时器和迭代器以免发生内存泄漏
      this.$once("hook:beforeDestroy", () => {
        clearInterval(this.timer)
        this.timer = null
        this.gen = null
      });
    },
    playAudio(is_new_wav) {
      if(is_new_wav){
        this.getDetail()
      }
      this.h5playAudio(is_new_wav)

      /*
      var jsondata = {}
      if(is_new_wav){
        let t = JSON.stringify({
          userID:this.userID,
          storyData:this.storyJson
        })
        jsondata['wav_url']=this.storyJson.wav
        jsondata['url_param']='contentdetail?params='+t+'&os_type='+this.os_type
        jsondata['title']=this.storyJson.title
      }
      else if(this.drag_progress>=0){
        jsondata['progress']=''+this.drag_progress
        this.drag_progress=-100
      }
      else if(this.playing){
        jsondata['play_status']="0"
        this.playing = false
        this.playicon=require('../assets/audio-icon-play.png')
      }
      else if(!this.playing){
        jsondata['play_status']="1"
      }
      //
      if (this.os_type=='android'){
        window.android.playAudio(JSON.stringify(jsondata))
      } 
      else if(this.os_type=='ios'){
        window.webkit.messageHandlers.playAudio.postMessage(jsondata)
      }  
      else {
        this.h5playAudio(is_new_wav)
      }
      */
    },
    h5playAudio(is_new_wav) {
      if(this.sound===null || is_new_wav==1) {
        if(this.sound){
          this.sound.stop()
        }
        console.log("initial sound",this.storyJson.wav)
        this.sound = new Howl({
          src: [this.storyJson.wav],

          onplay:() => {
            console.log("开始播放啦")
            this.playicon=require('../assets/audio-icon-pause.png');
            requestAnimationFrame(this.getPosition);
          },
          onend:() => {
            if(this.currIdx>=0){
              this.selectStory(this.currIdx+1)
            }
          },
          onloaderror:() => {
            Toast("声音导入失败")
          },
          onplayerror:() => {
            Toast("声音播放失败")
          },
        })
      }
      this.sound[this.sound.playing()? 'pause' : 'play']()
    },
    
    onProgressChange() {
      //if(this.os_type=='android' || this.os_type=='ios'){
      //  this.drag_progress=this.progressvalue
      //  this.playAudio(0)
      //}
      //else{
        var sec=this.sound?this.progressvalue*this.sound.duration()/100:0
        console.log("change to",this.progressvalue,sec)
        this.sound && this.sound.seek(sec)
      //}
    },
    dragStart() {
      this.touching = true;
    },
    dragEnd() {
      this.touching = false;
      if(this.sound!=null) {
        requestAnimationFrame(this.getPosition);
      }
    },
    goback() {
      this.sound && this.sound.stop();
      this.$router.back()
    },
    buy(){
      this.sound && this.sound.stop()
      
      if(this.userID.length==0){
        if(this.os_type=="android"){
          window.android.doLogin("")
        }
        else if (this.os_type=="ios"){
          window.webkit.messageHandlers.doLogin.postMessage(null)
        }
        else{
          Toast("你还没有登录哦")
        }
      }
      else {
        let json_param = {
          'url_param':"marketshow?type=0&userID=" + this.userID + '&os_type=' + this.os_type,
          'title':""
        }
        //
        if(this.os_type=="android"){
          window.android.gotoDetail(JSON.stringify(json_param))
        }
        else if (this.os_type=="ios"){
          window.webkit.messageHandlers.gotoDetail.postMessage(json_param)
        }
        else{
          Toast("请联系客服报名哦!")
        }
      }
    }
  }
};
</script>

<style scoped>
#contentdetail {
  padding:0.1rem 0.15rem;
}

#contentdetail .brief {
  margin-top: 0.1rem;
  font-size: 0.16rem;
  width:3.45rem;
  color:black;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden; 
  text-overflow: ellipsis; 
  display: -webkit-box; 
  -webkit-line-clamp: 2; 
  line-clamp: 2; 
  -webkit-box-orient: vertical;
  line-height: 0.23rem;
}
#contentdetail .anchor {
  margin:0.1rem 0rem;
  flex-direction: row;
  display: flex;
  position: relative;
  align-items: center;
}
#contentdetail .anchorimage{
  display: block;
  width: 0.3rem;
  height: 0.3rem;
  border-radius:0.15rem;
}

#contentdetail .anchorname {
  font-size: 0.1rem;
  padding-left: 0.1rem;
  width:1.8rem;
  color:rgb(180, 180, 180);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#contentdetail .coverimage{
  display: block;
  width: 3.45rem;
  height: 1.94rem;
  border-radius:0.05rem;
}
 #contentdetail .playarea {
  flex-direction:row;
  display:flex;
  position: relative;
  padding:0.12rem;
  background: rgb(247, 247, 247);
  border-radius:0.05rem;
}
#contentdetail .playbutton {
  display: block;
  width: 0.4rem;
  height: 0.4rem;
}
 #contentdetail .progress {
  padding-top:0.2rem;
  flex-direction:column;
  display:flex;
  position: relative;
  margin-left:0.15rem;
}
#contentdetail .sliderprogress {
  width: 2.65rem;
}
#contentdetail .textprogress {
  padding-top:0.05rem;
  direction:rtl;
  font-size: 0.12rem;
  color:gray;
}

#contentdetail .salebutton {
  margin:0.5rem;
  padding:0.1rem;
  color: #72C495;
  font-size:0.15rem;
  text-align: center;
  border: 0.01rem solid #72C495;
  border-radius: 0.1rem;
}

#contentdetail .detail_info {
  padding: 0.1rem;
  font-size: 0.15rem;
}
#contentdetail .detail_image {
  display: block;
  width: 3.45rem;
  border-radius:0.05rem;
}
</style>


<template>
  <div id="contentflow" @touchstart='touchstart' @touchmove='touchmove'>
    <div class="scroll_tips" v-if="scroll_top==0">{{scroll_tips}}</div>
    <div class='story' v-for="(item,index) in storylist" :key="index">
      <div style="position:relative;" @click="gotoDetail(item,index)">
        <img class="coverimage" v-bind:src="item.cover_image_url" alt="">
        <div style="position: absolute;left: 1.45rem;top: 0.75rem;">
          <img style="display: block; width:0.4rem; height:0.4rem;opacity: 0.8;" src="../assets/floatplay2.png">
        </div>
      </div>
      <div class="brief">{{item.brief_info}}</div>
      <div v-if="item.have_sub_album" class="brief">
        <span style="color:burlywood;font-weight:border;">专辑</span>
        &nbsp;&nbsp;{{item.sub_album_data.title}}&nbsp;&nbsp;共{{item.sub_album_data.story_num}}集
      </div>
      <div class='anchor'>
        <div><img class="anchorimage" v-bind:src="item.author_image_url" alt=""></div>
        <div class="anchorname">{{item.author_name}}</div>
        <!--
        <div class="playarea2" @click="clickstore(index)">
          <img class='playbutton' v-if='item.user_store_1==false' v-bind:src="storeicon1" alt="">
          <img class='playbutton' v-if='item.user_store_1==true' v-bind:src="storeicon2" alt="">
          <div class="text_tips" >{{item.store_num_1}}</div>
        </div>
        <div class="playarea2" @click="clicklike(index)">
          <img class='playbutton' v-if='item.user_like_1==false' v-bind:src="likeicon1" alt="">
          <img class='playbutton' v-if='item.user_like_1==true' v-bind:src="likeicon2" alt="">
          <div class="text_tips" >{{item.like_num_1}}</div>
        </div>
        -->
      </div>
    </div>
    <div v-if="storylist.length>0 && show_mode==1" class="scroll_tips" style="padding: 0.3rem;" @click="downApp">更多内容与优质体验,请下载APP>></div>
    <div v-else class="scroll_tips">{{scroll_tips}}</div>
  </div>
</template>

<script>
//import { Toast } from 'vant'
import axios from 'axios'
import {Howl} from 'howler'

export default {
    data() {
        return {
            userID: "",
            subAlbumID: "",
            storylist: [],
            click_index_list: [],
            scroll_tips: "",
            scroll_top: 0,
            scroll_top_moveY: -1,
            loading: false,
            show_mode:0,
            //storeicon1: require("../assets/store1.png"),
            //storeicon2: require("../assets/store2.png"),
            //likeicon1: require("../assets/like1.png"),
            //likeicon2: require("../assets/like2.png"),

            os_type:''
        };
    },
    created() {
        //Toast("h5 created")
        document.title = "通途英语";
        this.getRouterData();
        console.log("created contentflow 开始监听scroll");
        window.addEventListener("scroll", this.scroll);
    },
    activated() {
        //console.log("activated contentflow 开始监听scroll");
        //window.addEventListener("scroll", this.scroll);
    },
    deactivated() {
        //console.log("deactivated contentflow 销毁监听scroll");
        //window.removeEventListener("scroll", this.scroll);
    },
    destroyed() {
        console.log("destroyed contentflow 销毁监听scroll");
        window.removeEventListener("scroll", this.scroll);
        this.loadStory(true, 0);
    },
    methods: {
        getRouterData() {
            console.log("route", window.location.href);
            let params = JSON.parse(this.$route.query.params)
            this.userID = params.userID;
            this.subAlbumID = params.subAlbumID

            if(window.location.href.includes('os_type=')) this.os_type=this.$route.query.os_type
            if(window.location.href.includes('show_mode=')) this.show_mode=this.$route.query.show_mode

            this.loadStory(true, 10);
        },
        scroll() {
            if (this.loading || this.show_mode==1)
                return;
            this.scroll_tips = "";
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            let clientHeight = document.documentElement.clientHeight;
            let scrollHeight = document.documentElement.scrollHeight;
            this.scroll_top = scrollTop;
            //console.log("scroll pos",this.scroll_top)
            if (scrollTop + clientHeight >= scrollHeight - 1) {
                console.log("到底啦");
                this.scroll_tips = "正在努力加载...";
                this.loadStory(true, 7);
            }
            else if (scrollTop == 0) {
                console.log("到顶啦");
                this.scroll_tips = "";
            }
        },
        async loadStory(isBottom, storynum) {
            if (this.loading)
                return;
            this.loading = true;
            var record_list = [];
            for (var ii = 0; ii < this.click_index_list.length; ii++) {
                let index = this.click_index_list[ii];
                var like_num = this.storylist[index].like_num_1 - this.storylist[index].like_num;
                var store_num = this.storylist[index].store_num_1 - this.storylist[index].store_num;
                var click_num = this.storylist[index].click_num_1;
                if (like_num != 0 || store_num != 0 || click_num != 0) {
                    let t = {
                        "story_id": this.storylist[index].story_id,
                        "like_num": like_num,
                        "store_num": store_num,
                        "click_num": click_num
                    };
                    record_list.push(t);
                }
            }
            console.log("record_list", record_list);
            var first = true;
            if (this.storylist.length > 0)
                first = false;
            let request_data = {
                "type": "story_recommend",
                "data": {
                    "user_id": this.userID,
                    "record_list": record_list,
                    "need_story_num": storynum,
                    "content_scope": this.subAlbumID=="" ? 0:2,
                    "sub_album_id": this.subAlbumID,
                    'album_id':'S00D',
                    "first_time": first
                }
            };
            await axios.post("https://caixiaowen.com/tongtu", JSON.stringify(request_data))
                .then((result) => {
                for (var ii = 0; ii < this.click_index_list.length; ii++) {
                    let index = this.click_index_list[ii];
                    this.storylist[index]["user_like"] = this.storylist[index]["user_like_1"]
                    this.storylist[index]["user_store"] = this.storylist[index]["user_store_1"]
                    this.storylist[index]["like_num"] = this.storylist[index]["like_num_1"]
                    this.storylist[index]["store_num"] = this.storylist[index]["store_num_1"]
                    this.storylist[index]["click_num_1"] = 0;
                }
                this.click_index_list.length = 0;
                let tmplist = result.data["story_list"];
                this.scroll_tips = "";
                if (tmplist.length == 0 && storynum > 0)
                    this.scroll_tips = "没有数据了.";
                tmplist.forEach((item) => {
                    item["user_like"] = item['history']["user_like"]
                    item["user_store"] = item['history']["user_store"]
                    item["like_num"] = item['history']["like_num"]
                    item["store_num"] = item['history']["store_num"]
                    item["user_like_1"] = item["user_like"]
                    item["user_store_1"] = item["user_store"]
                    item["like_num_1"] = item["like_num"]
                    item["store_num_1"] = item["store_num"]
                    item["click_num_1"] = 0
                    if(Object.prototype.hasOwnProperty.call(item,'sub_album_data')){
                        item.have_sub_album=true
                    }
                    else {
                        item.have_sub_album=false
                    }
                    if (isBottom) {
                        this.storylist.push(item);
                    }
                    else {
                        this.storylist.unshift(item);
                    }
                });
                this.loading = false;
                if (!first && !isBottom) {
                    let voicemp3 = require("../assets/recommendok.mp3");
                    new Howl({ src: [voicemp3], }).play();
                }
            }).catch((error) => {
                console.log(error);
                this.loading = false;
                return;
            });
        },
        updateClickList(index) {
            var ii = 0;
            for (ii = 0; ii < this.click_index_list.length; ii++) {
                if (this.click_index_list[ii] == index)
                    break;
            }
            if (ii == this.click_index_list.length) {
                this.click_index_list.push(index);
            }
        },
        clickstore(index) {
            if (this.storylist[index].user_store_1 == false) {
                this.storylist[index].user_store_1 = true;
                this.storylist[index].store_num_1++;
            }
            else {
                this.storylist[index].user_store_1 = false;
                this.storylist[index].store_num_1--;
            }
            this.updateClickList(index);
            this.loadStory(true, 0);
        },
        clicklike(index) {
            if (this.storylist[index].user_like_1 == false) {
                this.storylist[index].user_like_1 = true;
                this.storylist[index].like_num_1++;
            }
            else {
                this.storylist[index].user_like_1 = false;
                this.storylist[index].like_num_1--;
            }
            this.updateClickList(index);
        },
        gotoDetail(item, index) {
            this.storylist[index].click_num_1++;
            this.updateClickList(index);
            let params = JSON.stringify({
                userID: this.userID,
                storyData: item
            });
            //
            let jsondata = {
                'url_param':"contentdetail?params=" + params + '&os_type='+this.os_type,
                'title':item.title,
                'showPlayer':"0"
            }
            if(this.os_type=='android'){
                window.android.gotoDetail(JSON.stringify(jsondata))
            }
            else if(this.os_type=='ios'){
                window.webkit.messageHandlers.gotoDetail.postMessage(jsondata)
            }
            else {
                this.$router.push({
                    name: 'contentdetail',
                    query: { params }
                })
            }
        },
        touchstart() {
            this.scroll_top_moveY = -1;
        },
        touchmove(e) {
            if(this.show_mode==1) return
            //
            if (this.scroll_top != 0) {
                this.scroll_top_moveY = -1;
            }
            else {
                if (this.scroll_top_moveY == -1) {
                    this.scroll_top_moveY = e.touches[0].clientY;
                }
                else if (e.touches[0].clientY - this.scroll_top_moveY > document.documentElement.clientHeight * 0.3) {
                    this.scroll_top_moveY = -1;
                    this.scroll_tips = "正在努力加载...";
                    this.loadStory(false, 7);
                }
            }
            //console.log(this.scroll_top,this.scroll_top_moveY,e.touches[0].clientY)
        },
        downApp(){
            this.$router.push({
                name: 'appdown',
                query: {}
            })
        }
    }
};
</script>

<style scoped>
#contentflow {
  background:rgb(245, 246, 247);
  min-height: 100vh;
  height:auto;
}
#contentflow .story {
  background:white;
  margin:0rem 0.15rem 0.15rem 0.15rem;
  padding:0.1rem;
  border-radius:0.12rem;
}
#contentflow .coverimage{
  display: block;
  width: 3.25rem;
  height: 1.83rem;
  border-radius:0.06rem;
}
#contentflow .brief {
  margin-top: 0.1rem;
  font-size: 0.16rem;
  width:3.25rem;
  color:black;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden; 
  text-overflow: ellipsis; 
  display: -webkit-box; 
  -webkit-line-clamp: 2; 
  line-clamp: 2; 
  -webkit-box-orient: vertical;
  line-height: 0.23rem;
}
#contentflow .anchor {
  margin-top:0.1rem;
  flex-direction: row;
  display: flex;
  position: relative;
  align-items: center;
}
#contentflow .anchorimage{
  display: block;
  width: 0.3rem;
  height: 0.3rem;
  border-radius:0.15rem;
}

#contentflow .anchorname {
  font-size: 0.1rem;
  padding-left: 0.1rem;
  width:1.8rem;
  color:rgb(180, 180, 180);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#contentflow .playarea {
  padding: 0.1rem 0.1rem;
  flex-direction:row;
  display:flex;
  justify-content: space-around;
}
#contentflow .playarea2 {
  flex-direction: column;
  display: flex;
  position: relative;
  padding-left: 0.25rem;
}

#contentflow .playbutton {
  display: block;
  width: 0.2rem;
  height: 0.2rem;
}

#contentflow .text_tips {
  padding-top: 0.05rem;
  font-size: 0.1rem;
  text-align: center;
  color:rgb(180, 180, 180);
}

#contentflow .scroll_tips {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  text-align: center;
  font-size: 0.14rem;
  color:rgb(0, 0, 0, 0.4);
}
</style>


import axios from 'axios'
import { Toast } from 'vant'
import wx from 'weixin-js-sdk'

export async function wxConfig(the_url, params) {
  let request_data = {
    'type': 'weixin_signature',
    'data': {
      'url': the_url,
    }
  }
  var jsApiList2 = []
  if (Object.prototype.hasOwnProperty.call(params, 'share')) {
    jsApiList2.push('updateAppMessageShareData')
    jsApiList2.push('updateTimelineShareData')
  }
  if (Object.prototype.hasOwnProperty.call(params, 'record')) {
    jsApiList2.push('startRecord')
    jsApiList2.push('stopRecord')
    jsApiList2.push('uploadVoice')
  }

  console.log('the_url', the_url)
  const result = await axios.post('https://caixiaowen.com/tongtu', JSON.stringify(request_data))
  console.log("weixin_signature", result.data)
  if (Object.prototype.hasOwnProperty.call(result.data, 'signature')) {
    wx.config({
      debug: false,
      appId: result.data.appId,
      timestamp: result.data.timestamp,
      nonceStr: result.data.nonceStr,
      signature: result.data.signature,
      jsApiList: jsApiList2
    });

    wx.ready(function () {
      //Toast("jssdk config成功")
      if (Object.prototype.hasOwnProperty.call(params, 'share')) {
        var param2 = { 
          title: params.shareTitle,
          desc: params.shareDesc,
          link: params.shareLink,
          imgUrl: require("../assets/logo_wx_share.png"),
          success: function () {},
          fail: function() {}
        }
        wx.updateAppMessageShareData(param2)
        wx.updateTimelineShareData(param2)
      }
    })
    wx.error(function () {
      Toast("jssdk config失败")
    })
  }
}

export function arrayBufferToBase64(buffer) {
  var binary = '';
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

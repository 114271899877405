<template>
  <div class="video-chat">
    <div v-if="storyData.cover_image_url" class="video-chat"
      :style="{ 'background': 'url(' + storyData.cover_image_url + ')', 'background-size': '100% 100%', 'background-repeat': 'no-repeat', 'background-attachment': 'fixed', 'filter': 'blur(25px)' }">
    </div>
    
    <div style="width:100%;position: fixed;top: 0;left: 0;">
      <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true"
        @play="onPlayerPlay($event)" @pause="onPlayerPause($event)" @ended="onPlayerEnded($event)"
        @timeupdate="onPlayerTimeupdate($event)" :options="playerOptions">
      </video-player>
    </div>

    <div class="message-container" ref="messagecontainer" style="position: fixed;bottom: 0;left: 0;">
      <div v-for="(s, index) in messages" :key="index"
        :class="{ 'message-left': s.role != 'B', 'message-right': s.role === 'B' }">
        <div v-if='s.options' style="width:2.8rem;">
          <div>{{ s.title }}</div>
          <div v-for="(txt, index2) in s.options" :key="index2" @click="answer(index, index2)">
            <div class="option" :style="{ 'background': s.colors[index2] }">
              <div style="color:#666666;font-weight: bold;">{{ alphamap[index2] }}</div>
              <div style="color:#222222;margin-left: 0.12rem;">{{ txt }}</div>
              <div v-if="s.confirm_txt.length > 0 && s.click_idx == index2">
                <div style="position: absolute;top: 0rem;right: -0.1rem;">
                  <img v-if="s.click_idx == s.answer_idx" style="display: block; width:0.2rem; height:0.2rem;" src="../assets/correct.png">
                  <img v-else style="display: block; width:0.2rem; height:0.2rem;" src="../assets/wrong.png">
                </div>
              </div>
            </div>
          </div>
          <div v-if="s.confirm_txt.length > 0" style="text-align: center;padding:0.05rem;font-weight: bold;">
            {{ s.confirm_txt }}
          </div>
          <div v-else class="confirm" @click="confirm(index)">确认</div>
        </div>

        <div v-if='s.assess_ref_list' style="width:2.8rem;">
          <div>{{ s.title }}</div>
          <div v-for="(txt, index2) in s.assess_ref_list" :key="index2">
            <div class="option">
              <div v-if="s.assess_ref_list.length>1" style="color:#666666;font-weight: bold;">{{ alphamap[index2] }}</div>
              <div style="color:#222222;margin-left: 0.12rem;">{{ txt }}</div>
              <div v-if="(s.score >= 85 || s.assess_num >= 3) && s.answer_idx == index2">
                <div style="position: absolute;top: 0rem;right: -0.1rem;background:#76d087;width:0.2rem;height:0.2rem;border-radius: 0.1rem;">
                  <div style="font-size: 0.12rem;text-align: center;color:white;">{{ s.score }}</div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="s.score >= 85 || s.assess_num >= 3" style="text-align: center;font-weight: bold;">
            <span v-if="s.assess_ref_list.length>1">正确选项是{{ alphamap[s.answer_idx] }},</span>
            发音不错哦
          </div>
          <div v-else>
            <div style="background:#009FF8;width:0.5rem;height:0.5rem;border-radius: 0.25rem;margin:auto;position: relative;"
              @touchstart='recordStart(index)' @touchend='recordEnd(index)'>
              <img class="recordbutton" src="../assets/record.png" alt="">
              <div v-if="s.score > 0" style="position: absolute;top: 0;right: -0.2rem;">{{ s.score }}</div>
            </div>
            <div style="text-align: center;font-size:0.12rem;">长按录音</div>
          </div>
        </div>

        <div v-if='s.chat_text' style="white-space: pre-line;">
          {{ s.chat_text }}
        </div>
      </div>
      <div v-if="show_pannel" class="message-left">
        <div class="progress-tips">
          <van-slider inactive-color='#666666' active-color='#009FF8' button-size='0.1rem' v-model="progressvalue"
            @change="onProgressChange" @drag-start="dragStart" @drag-end="dragEnd" />
          <div class="horizontal" style="justify-content: space-between;">
            <div class="textprogress">{{ progressvaluestr1 }}</div>
            <div class="textprogress">{{ progressvaluestr2 }}</div>
          </div>
        </div>
        <div class="horizontal" style="justify-content: space-between;padding:0 0.3rem;">
          <img style="display: block;width: 0.36rem;height: 0.36rem;" src="../assets/audio-icon-prev2.png" @click="playPrev" alt="">
          <img style="display: block;width: 0.36rem;height: 0.36rem;" v-bind:src="playicon" @click="playVideo" alt="">
          <img style="display: block;width: 0.36rem;height: 0.36rem;" src="../assets/audio-icon-next2.png" @click="playNext" alt="">
        </div>
      </div>
    </div>

    <div style="position: fixed;bottom: 0.1rem;right: 0.1rem;">
      <img style="display: block;width: 0.3rem;height: 0.3rem;" src="../assets/audio-icon.png" @click="showPannel">
      <img style="display: block;width: 0.3rem;height: 0.3rem; margin-top:0.2rem;" src="../assets/clean-screen.png" @click="cleanMessage">
    </div>
  </div>
</template>

<script>
import { Howl } from 'howler'
import axios from 'axios'

export default {
  data() {
    return {
      userID: '10000000',
      storyID: 'S00J001',

      storyData: {},
      messages: [],
      done_idx: -1,
      asking: false,
      //

      show_pannel: false,

      alphamap: ['A', 'B', 'C', 'D', 'E', 'F', 'G'],

      playicon: require('../assets/audio-icon-play2.png'),
      progressvalue: 0,
      sound: null,
      touching: false,
      progressvaluestr1: "00:00",
      progressvaluestr2: "00:00",
      //

      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: true, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto',
        language: 'zh-CN',
        //aspectRatio: '9:16',
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [],
        poster: require("../assets/cover_video.jpg"), //你的封面地址
        // width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        flash: { hls: { withCredentials: false } },
        html5: { hls: { withCredentials: false } },
        controls: false,
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
          remainingTimeDisplay: false,
          fullscreenToggle: false  //全屏按钮
        }
      },
      playStatus: -1,
    };
  },

  created() {
    document.title = "";
    this.getRouterData();
  },
  beforeDestroy() {
    this.sound && this.sound.stop()
  },

  methods: {
    getRouterData() {
      console.log("route", window.location.href);
      //this.userID = this.$route.query.user_id
      //this.storyID = this.$route.query.story_id

      this.loadData()
    },

    async loadData() {
      let request_data = {
        "type": "story_data",
        "data": {
          "user_id": this.userID,
          "story_id": this.storyID
        }
      };
      await axios.post("https://caixiaowen.com/fancast", JSON.stringify(request_data))
        .then((result) => {
          console.log(result.data)
          this.storyData = result.data.story_data

          this.playerOptions.sources = [{
            type: "video/mp4",
            src: this.storyData.video
          }]
          this.scrollToBottom()
        }).catch((error) => {
          console.log(error)
        });
    },

    showPannel() {
      this.show_pannel = !this.show_pannel
      this.scrollToBottom()
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.messagecontainer;
        container.scrollTop = container.scrollHeight
      });
    },

    cleanMessage(){
      this.show_pannel = false
      if(this.messages.length>0){
        let t = this.messages[this.messages.length-1]
        this.messages = []
        if(this.asking) this.messages.push(t)
      }
    },

    answer(no, index) {
      if(this.messages[no].confirm_txt.length>0) return

      if (this.messages[no].click_idx >= 0) {
        this.messages[no].colors[this.messages[no].click_idx] = 'rgba(153,153,153,0.08)'
      }
      this.messages[no].click_idx = index
      this.messages[no].colors[this.messages[no].click_idx] = 'rgba(153,153,153,0.8)'

      this.messages = this.messages.slice()
    },

    confirm(no) {
      if (this.messages[no].click_idx < 0) return

      if (this.messages[no].click_idx == this.messages[no].answer_idx) {
        this.messages[no].confirm_txt = "Congratulations, 答对了"
        let voicemp3=require('../assets/great.mp3')
        new Howl({src: [voicemp3],}).play()
      } else {
        this.messages[no].confirm_txt = "答错了, 正确答案是" + this.alphamap[this.messages[no].answer_idx]
      }
      this.messages = this.messages.slice()
      this.$refs.videoPlayer.player.play()
      this.asking = false
    },

    playAudio(no) {
      console.log("playAudio " + no)
    },

    recordStart(no) {
      console.log("recordStart " + no)
    },
    recordEnd(no) {
      this.messages[no].score = 30
      this.messages[no].assess_num += 1
      if(this.messages[no].assess_num==2) this.messages[no].score = 98
      this.messages = this.messages.slice()

      if (this.messages[no].score >= 85 || this.messages[no].assess_num >= 3) {
        this.$refs.videoPlayer.player.play()
        this.asking = false

        if(this.messages[no].score >= 85){
          let voicemp3=require('../assets/great.mp3')
          new Howl({src: [voicemp3],}).play()
        }
      }
    },

    // 开始播放回调
    onPlayerPlay(player) {
      console.log('player play!', player)

      if (this.playStatus == -1){
        this.cleanMessage()
        this.done_idx = -1
      }

      this.playStatus = 1
      this.playicon = require('../assets/audio-icon-pause2.png');

      if (this.asking) {
        this.$refs.videoPlayer.player.pause()
      }
    },

    // 暂停回调
    onPlayerPause(player) {
      console.log('player pause!', player)
      this.playStatus = 0
      this.playicon = require('../assets/audio-icon-play2.png');
    },

    // 视频播完回调
    onPlayerEnded(player) {
      console.log('player ended', player)
      this.playStatus = -1
      this.playicon = require('../assets/audio-icon-play2.png');

      this.messages.push({
        role: 'A',
        chat_text: '课程全部播放完成了！'
      })
    },

    onPlayerTimeupdate(player) {
      var duration = player.cache_.duration
      var soundseek = player.cache_.currentTime

      if (this.touching || duration <= 0) return

      this.progressvalue = soundseek * 100 / duration

      var t1 = parseInt(soundseek / 60)
      if (t1 < 10) t1 = '0' + t1
      var t2 = parseInt(soundseek % 60)
      if (t2 < 10) t2 = '0' + t2
      var t3 = parseInt(duration / 60)
      if (t3 < 10) t3 = '0' + t3
      var t4 = parseInt(duration % 60)
      if (t4 < 10) t4 = '0' + t4
      this.progressvaluestr1 = '' + t1 + ':' + t2
      this.progressvaluestr2 = '' + t3 + ':' + t4

      if (this.done_idx + 1 < this.storyData.point_list.length && soundseek >= this.storyData.point_list[this.done_idx + 1].start) {
        var t = this.storyData.point_list[this.done_idx + 1]
        if (t.options) {
          t.click_idx = -1
          t.confirm_txt = ""
          t.colors = []
          for (var i = 0; i < t.options.length; i += 1) t.colors.push('rgba(153,153,153,0.08)')

          this.asking = true
          this.$refs.videoPlayer.player.pause()
        }
        else if (t.assess_ref_list) {
          t.score = 0
          t.assess_num = 0

          this.asking = true
          this.$refs.videoPlayer.player.pause()
        }

        //
        this.messages.push(t)
        this.done_idx += 1
        this.scrollToBottom()
      }
    },

    playVideo() {
      if (this.playStatus == -1 || this.playStatus == 0){
        this.$refs.videoPlayer.player.play()
      }
      else {
        this.$refs.videoPlayer.player.pause()
      }
    },
    playPrev(){
      //
    },
    playNext(){
      //
    },

    onProgressChange() {
      //var sec = this.sound ? this.progressvalue * this.sound.duration() / 100 : 0
      //console.log("change to", this.progressvalue, sec)
      //this.sound && this.sound.seek(sec)
    },
    dragStart() {
      this.touching = true;
    },
    dragEnd() {
      this.touching = false;
    },
  }
};
</script>

<style>
.video-chat {
  background: rgb(245, 246, 247);
  min-height: 100vh;
  height: auto;
}

.message-container {
  max-height: 3rem;
  width: 3.45rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;

  padding: 0.05rem 0.15rem;
}

.message-left {
  align-self: flex-start;
  background-color: rgba(255, 255, 255, 0.7);
  color: rgba(34, 34, 34, 0.9);

  margin-bottom: 0.1rem;
  padding: 0.1rem;
  border-radius: 0.08rem;
  max-width: 2.8rem;
  font-size: 0.15rem;
  line-height: 0.22rem;
}

.message-right {
  align-self: flex-end;
  background-color: rgba(12, 192, 223, 0.6);
  color: rgba(255, 255, 255, 0.9);

  margin-bottom: 0.1rem;
  padding: 0.1rem;
  border-radius: 0.08rem;
  max-width: 2.8rem;
  font-size: 0.15rem;
  line-height: 0.22rem;
}

.horizontal {
  flex-direction: row;
  display: flex;
  position: relative;
  align-items: center;
}

.option {
  flex-direction: row;
  display: flex;
  position: relative;

  background: rgba(153, 153, 153, 0.08);
  padding: 0.05rem;
  margin: 0.1rem 0;
  border-radius: 0.1rem;
  align-items: center;
}

.confirm {
  text-align: center;
  border-radius: 0.1rem;
  font-size: 0.15rem;
  padding: 0.05rem;
  margin: 0 0.9rem;
  background: #009FF8;
}

.recordbutton {
  display: block;
  width: 0.18rem;
  height: 0.25rem;
  padding: 0.12rem 0.16rem;
  pointer-events: none;
}

.progress-tips {
  padding: 0.1rem;
  flex-direction: column;
  display: flex;
  position: relative;
  width: 2.6rem;
}

.textprogress {
  padding-top: 0.05rem;
  font-size: 0.12rem;
  color: black;
}
</style>

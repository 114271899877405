
<template>
  <div id="listencompre">
    <div style="padding-top:0.2rem;"><img class="coverimage"  v-bind:src="cover_image_url" alt=""></div>
    <div v-if="currId>=0" style="padding-bottom:1.4rem;">
      <div style="padding:0.15rem;font-size:0.15rem;color:#222222;">{{quesArr[currId].title}}</div>
      <div v-for="(item,index) in quesArr[currId].option" :key="index" @click="answer(currId,index,true)">
        <div class="option" :style="{'background': quesArr[currId].colorArr[index]}">
          <div style="color:#666666;font-size:0.16rem;font-weight: bold;">{{alphamap[index]}}</div>
          <div style="color:#222222;font-size:0.16rem;margin-left: 0.12rem;">{{item}}</div>
          <div v-if="quesArr[currId].user_answer==index && alphamap[index]==quesArr[currId].answer" style="position: absolute;top: 0rem;right: 0rem;">
            <img style="display: block; width:0.2rem; height:0.2rem;" src="../assets/correct.png">
          </div>
          <div v-if="quesArr[currId].user_answer==index && alphamap[index]!=quesArr[currId].answer" style="position: absolute;top: 0rem;right: 0rem;">
            <img style="display: block; width:0.2rem; height:0.2rem;" src="../assets/wrong.png">
          </div>
        </div>
      </div>
    </div>

    <div class="bottomarea">
      <div class="horizontal" style="padding:0.15rem;">
        <div class="textprogress">{{progressstr}}</div>
        <van-slider class='sliderprogress' active-color='#009FF8' button-size='0.1rem' v-model="progressvalue" @change="onProgressChange" 
        @drag-start="dragStart" @drag-end="dragEnd"/>
        <div class="textprogress">{{durationstr}}</div>
      </div>
      <div class="horizontal" style="padding:0.15rem 0.15rem 0.3rem 0.15rem;">
        <div class="replaystyle">
          <div><img class="replayicon" src="../assets/relisten.png" alt="" @click="rePlayMode()"></div>
          <div style="width:0.01rem;height:0.15rem;background: #BBBBBB;margin-right:0.05rem;"></div>
          <div v-if="clearAnswer" style="color:#666666;font-size:0.14rem;" @click="replay()">重新练</div>
          <div v-else style="color:#666666;font-size:0.14rem;" @click="replay()">从头听</div>
        </div>
        <div><img class='playbutton' v-bind:src="playicon" @click="playAudio" alt=""></div>
        <div v-if="currId>=0 && quesArr[currId].user_answer>=0" class="nextstyle" @click="nextTrainStep()">
          <div style="color:#009FF8;font-size:0.14rem;font-weight: bold;">
            <div v-if="currId==quesArr.length-1">下一步</div>
            <div v-else>确定</div>
          </div>
          <div><img class="nexticon" src="../assets/nextstep.png" alt=""></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import {Howl} from 'howler'
import axios from 'axios'

export default {
  data() {
    return {
      userID: "",
      albumID:'',
      storyID:'',
      crossInfo:{},

      os_type:"",

      quesArr:[],
      currId:-1,

      wav_url:'',
      cover_image_url:require('../assets/defaultcover.png'),

      alphamap:['A','B','C','D','E'],
      playicon: require('../assets/audio-icon-play.png'),
      sound: null,

      learn_second:0,
      last_time_pos:0,

      clearAnswer:true,

      progressstr:"00:00",
      durationstr:"00:00",
      progressvalue:0,
      touching:false
    };
  },
  created() {
    document.title = '通途英语'
    this.getRouterData()
  },
  beforeDestroy() {
    this.sound && this.sound.stop()
  },
  mounted(){
    const that = this
    window.autoPlay = that.autoPlay
    window.finish = that.finish
    window.refresh = that.refresh
  },
  
  methods: {
    async getRouterData() {
      console.log("route",window.location.href)
      let params = JSON.parse(this.$route.query.params)
      this.userID = params.userID
      this.albumID = params.albumID
      this.storyID = params.storyID
      this.crossInfo = params.crossInfo
      if(window.location.href.includes('os_type=')) this.os_type=this.$route.query.os_type

      let request_data = {
        'type' : 'compre_data',
        'data' : {
          'user_id':this.userID,
          'album_id':this.albumID,
          'story_id':this.storyID
        }
      }
      await axios.post('https://caixiaowen.com/tongtu',JSON.stringify(request_data))
      .then((result) =>{
        console.log(result.data)
        this.wav_url = result.data.story_data.wav
        this.cover_image_url = result.data.story_data.cover_image_url
        this.quesArr=result.data.compre_data.ques_list

        for(var i=0;i<this.quesArr.length;i++){
          this.quesArr[i]['colorArr']=[]
          this.quesArr[i]['user_answer']=-1
          for(var j=0;j<this.quesArr[i].option.length;j++){
            this.quesArr[i]['colorArr'].push('rgba(153,153,153,0.08)')
            var pos = this.quesArr[i].option[j].indexOf("|")
            if(pos>=0){
              this.quesArr[i].option[j]=this.quesArr[i].option[j].substring(0,pos)
            }
          }
        }

        let choiceLst=result.data.history.choice_list
        for(i=0;i<choiceLst.length;i++){
          if(choiceLst[i].choices[0]=='A')this.answer(choiceLst[i].no,0,false)
          if(choiceLst[i].choices[0]=='B')this.answer(choiceLst[i].no,1,false)
          if(choiceLst[i].choices[0]=='C')this.answer(choiceLst[i].no,2,false)
          if(choiceLst[i].choices[0]=='D')this.answer(choiceLst[i].no,3,false)
        }
      }).catch((error) =>{
        console.log(error)
      })

      this.currId=0
      if(this.os_type=='ios') {
        window.webkit.messageHandlers.autoPlay.postMessage(null)
      }
      else {
        this.playAudio()
      }
    },
    
    refresh(){
      //
    },
    finish(){
      this.sound && this.sound.stop()
      this.sound=null
    },
    autoPlay(){
      this.playAudio()
    },
    getPosition() {
      var duration = this.sound.duration();
      if(duration>0) {
        var soundseek=this.sound.seek()
        this.progressvalue = soundseek*100/duration;

        var t1=parseInt(soundseek/60)
        if(t1<10)t1='0'+t1
        var t2=parseInt(soundseek%60)
        if(t2<10)t2='0'+t2
        var t3=parseInt(duration/60)
        if(t3<10)t3='0'+t3
        var t4=parseInt(duration%60)
        if(t4<10)t4='0'+t4
        this.progressstr = ''+t1+':'+t2
        this.durationstr = ''+t3+':'+t4

        if(soundseek>this.last_time_pos && soundseek<this.last_time_pos+3){
          this.learn_second += soundseek-this.last_time_pos
        }
        this.last_time_pos = soundseek
        //console.log(this.learn_second)
      }

      var s = this.sound.playing()
      if(!s) {
        this.playicon=require('../assets/audio-icon-play.png');
      }
      if( s && !this.touching) {
        requestAnimationFrame(this.getPosition);
      }
    },
    playAudio() {
      if(this.sound===null) {
        console.log("initial sound",this.wav_url)
        this.sound = new Howl({
          src: [this.wav_url],

          onplay:() => {
            console.log("开始播放啦")
            this.playicon=require('../assets/audio-icon-pause.png');
            requestAnimationFrame(this.getPosition);
          },
          onloaderror:() => {
            Toast("声音导入失败")
          },
          onplayerror:() => {
            Toast("声音播放失败")
          },
        })
      }
      this.sound[this.sound.playing()? 'pause' : 'play']()
    },
    replay(){
      if(this.clearAnswer){
        for(var i=0;i<this.quesArr.length;i++){
          this.quesArr[i]['colorArr']=[]
          this.quesArr[i]['user_answer']=-1
          for(var j=0;j<this.quesArr[i].option.length;j++){
            this.quesArr[i]['colorArr'].push('rgba(153,153,153,0.08)')
          }
        }
        console.log('clear answer')
        //this.clearAnswer=false
      }

      if(this.sound!=null){
        console.log('replay')   
        this.currId=0
        this.sound.seek(0)
        if(!this.sound.playing()){
          this.sound.play()
        }
      }
    },
    onProgressChange() {
      var sec=this.sound?this.progressvalue*this.sound.duration()/100:0
      console.log("change to",this.progressvalue,sec)
      
      this.sound && this.sound.seek(sec)
    },
    dragStart() {
      this.touching = true;
    },
    dragEnd() {
      this.touching = false;
      if(this.sound!=null) {
        requestAnimationFrame(this.getPosition);
      }
    },
    answer(no,index,is_click){
      if(this.quesArr[no].user_answer>=0 && this.quesArr[no].user_answer!=index){
        var t=this.quesArr[no].user_answer
        this.quesArr[no].colorArr[t]='rgba(153,153,153,0.08)'
      }
      this.quesArr[no].user_answer=index

      if(this.alphamap[index]==this.quesArr[no].answer){
        this.quesArr[no].colorArr[index]='rgba(79,211,127,0.08)'

        if(is_click){
          let voicemp3=require('../assets/great.mp3')
          new Howl({src: [voicemp3],}).play()
        }
      }
      else{
        this.quesArr[no].colorArr[index]='rgba(255,107,84,0.08)'
      }
      this.quesArr = this.quesArr.slice()
    },
    nextTrainStep(){
      let request_data = {
        'type' : 'save_exercise_data',
        'data' : {
          'user_id':this.userID,
          'album_id':this.albumID,
          'story_id':this.storyID,
          'data_type':'listen_compre',
          'learn_time':this.learn_second,
          'data':{
            'no':this.currId,
            'choices':[this.alphamap[this.quesArr[this.currId].user_answer]]
          }
        }
      }
      this.learn_second=0
      axios.post('https://caixiaowen.com/tongtu',JSON.stringify(request_data))
      .then((result) =>{
        console.log('答案上传成功',result.data)
      }).catch((error) =>{
        console.log(error)
      })

      if(this.currId<this.quesArr.length-1){
        this.currId++
      }
      else{
        this.sound && this.sound.stop()
        
        let params = JSON.stringify({
          userID:this.userID,
          albumID:this.albumID,
          storyID:this.storyID,
          crossInfo:this.crossInfo
        })
        let json_param = {
          'url_param':"expresswords?params=" + params + '&os_type=' + this.os_type,
          'title':"单词闯关"
        }

        if(this.os_type=="android"){
          window.android.gotoDetail(JSON.stringify(json_param))
        }
        else if (this.os_type=="ios"){
          window.webkit.messageHandlers.gotoDetail.postMessage(json_param)
        }
        else{
          this.$router.push({
            name: 'expresswords',
            query: {params}
          })
        }
      }
    },
    rePlayMode(){
      this.clearAnswer = !this.clearAnswer
    }
  }
};
</script>

<style scoped>
#listencompre {
  min-height: 100vh;
  height:auto;
}

#listencompre .coverimage {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius:0.75rem;
  margin:0.1rem auto;
  animation: rotateImg 10s linear infinite;
}
@keyframes rotateImg {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#listencompre .option {
  flex-direction: row;
  display: flex;
  position: relative;

  background:rgba(153,153,153,0.08);
  padding:0.15rem;
  margin:0.15rem;
  border-radius: 0.1rem;
  align-items: center;
}

#listencompre .horizontal {
  flex-direction: row;
  display: flex;
  position: relative;
}

#listencompre .bottomarea {
  position: fixed;
  width:100%;
  bottom: 0rem;
  background:white;
}

#listencompre .sliderprogress {
  width: 2.4rem;
  margin:auto;
}
#listencompre .textprogress {
  font-size: 0.12rem;
  color:#666666;
  margin:auto;
}
#listencompre .replaystyle{
  flex-direction: row;
  display: flex;
  position: relative;

  background:rgba(153,153,153,0.08);
  padding:0.08rem 0.15rem;
  margin:0.1rem;
  border-radius: 0.18rem;
  align-items: center;
}
#listencompre .nextstyle{
  flex-direction: row;
  display: flex;
  position: relative;

  background:rgba(0,159,248,0.08);
  padding:0.08rem 0.15rem;
  margin:0.1rem 0.1rem 0.1rem 0.45rem;
  border-radius: 0.18rem;
  align-items: center;
}
#listencompre .replayicon {
  display: block;
  width: 0.14rem;
  height: 0.14rem;
  margin-right: 0.06rem;
}
#listencompre .nexticon {
  display: block;
  width: 0.06rem;
  height: 0.1rem;
  margin-left: 0.06rem;
}
#listencompre .playbutton {
  display: block;
  width: 0.55rem;
  height: 0.55rem;
  margin-left:0.27rem;
}

</style>

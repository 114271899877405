
<template>
  <div id="msgsend">
    <div style="margin:0.4rem;">
      <div style="font-size: 0.18rem;margin:0.2rem;">
        <input ref="phone_ref" type="number" style="border-style: dotted;padding: 0.1rem;width:2.35rem;height: 0.22rem;" placeholder="请输入手机号">
      </div>
      <div class="paybutton1" @click="phone_register">
        <div style="font-size: 0.18rem;color: white;text-align: center;padding: 0.08rem 0;">发送短信</div>
      </div>
      <div v-for="(item,index) in msglist" :key="index">
        <div style="font-size: 0.15rem;color: gray;margin: 0rem 0.2rem 0.1rem 0.2rem;">
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { Toast } from 'vant';

export default {
  data() {
    return {
      msglist:[],
      waiting:false
    };
  },
  created() {
    document.title = '通途英语'
  },
  methods: {
    async phone_register(){
      if(this.waiting) return
      this.waiting = true

      let phonenum = this.$refs.phone_ref.value
      if(phonenum.length!=15 && phonenum.length!=4){
        Toast("输入格式错误")
        this.waiting = false
        return
      }

      let request_data = {
        'type': 'phone_register',
        'data': {
          'phone_number': phonenum
        }
      }
      await axios.post('https://caixiaowen.com/tongtu', JSON.stringify(request_data))
        .then((result) => {
          console.log(result.data)
          this.waiting = false
          Toast(result.data.err_info)
          if(result.data.err_no==0){
            if (Object.prototype.hasOwnProperty.call(result.data, 'msg_list')) {
              this.msglist = result.data.msg_list
            }
          }
        }).catch((error) =>{
          console.log(error)
          this.waiting = false
        })
    },
  }
};
</script>

<style scoped>
#msgsend .paybutton1 {
  background: url("../assets/sales1/pay_icon.png");
  background-size: cover;
  margin:0.1rem 0.2rem;
  width: 2.55rem;
  height: 0.37rem;
}
</style>

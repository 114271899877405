<template>
  <div></div>
</template>

<script>
import axios from 'axios'
import { Toast } from 'vant'

export default {
  data() {
    return {
      userID:'',
      phoneNum:'',
      openID:'',
      gotoType: -1
      //
    };
  },
  async created() {
    document.title = '通途英语'
    console.log("route", window.location.href)

    this.gotoType = this.$route.query.gotoType

    if (window.location.href.includes('code=')) {
      let request_data = {
        'type': 'weixin_register',
        'data': {
          'wx_code': this.$route.query.code,
          'user_name': "" //this.$route.query.state
        }
      }
      await axios.post('https://caixiaowen.com/tongtu', JSON.stringify(request_data))
        .then((result) => {
          console.log("weixin_register", result.data)
          if (Object.prototype.hasOwnProperty.call(result.data, 'user_id')) {
            this.userID = result.data.user_id
            this.openID = result.data.open_id
            this.$root.wxConfigUrl = window.location.href
            //
            console.log("get userID from server", this.userID)

            if (this.gotoType == 0) {
              let params = JSON.stringify({
                userID: this.userID,
                albumID: 'S00F',
                subAlbumID: 'S00F_1_1',
                stage: 3
              });
              this.$router.push({
                name: 'storylist',
                query: {
                  'params': params,
                  'show_mode': 1
                }
              })
            }
            else if (this.gotoType == 1) {
              this.$router.push({
                name: 'contentflow',
                query: {
                  'userID': this.userID
                }
              })
            }
            else if (this.gotoType == 2) {
              let state = JSON.parse(this.$route.query.state)

              let params = JSON.stringify({
                userID:this.userID,
                albumID:state.album_id,
                storyID:state.story_id,
                crossInfo:{}
              });
              this.$router.push({
                name: 'expresswords',
                query: {
                  'params': params,
                  'show_mode': 1
                }
              })
            }
            else if (this.gotoType >= 1000) {
              this.$router.push({
                name: 'marketshow',
                query: {
                  userID:'',
                  openID:this.openID,
                  type:this.gotoType-1000
                }
              })
            }
          } else {
            Toast("登录失败,code=001")
          }
        }).catch((error) => {
          console.log(error)
          Toast("登录失败,code=002")
        })
    } else {
      Toast("登录失败,code=003")
    }
  },
  methods: {
  }
};
</script>

<style scoped></style>


<template>
  <div id="expresswords">
    <div v-if="!showtips" class="texttips" @click="showtext()">
      <div><img class="tipsimage1"  src="../assets/eyetips1.png" alt=""></div>
      <div style="color:#009FF8;font-size:0.14rem;text-align:center;padding-top:0.2rem;">点击查看整句</div>
    </div>
    <div v-if="showtips" class="texttips" style="background:#414B56;" @click="showtext()">
      <div><img class="tipsimage2"  src="../assets/eyetips2.png" alt=""></div>
      <div style="color:white;font-weight:450;font-size:0.16rem;line-height: 0.25rem;padding:0.15rem;" v-html="infoArr[currId].text"></div>
    </div>
    
    <div v-if="currId>=0" style="margin-right:0.15rem;">
      <div style="padding:0.15rem;font-size:0.15rem;color:#222222;">{{currId+1}}.&nbsp;{{infoArr[currId].question.title}}</div>
      <div v-if="infoArr[currId].question.type=='单词点选题'" class="horizontal" style="flex-wrap: wrap;">
        <div v-for="(item,index) in infoArr[currId].question.option2" :key="index" @click="answer(currId,index,true)">
          <div class="word" :style="{'width':'1.45rem','background': item.color}">
            <div>{{item.word}}</div>
            <div v-if="item.selected && item.ref_pos>=0" style="position: absolute;top: 0rem;right: 0rem;">
              <img style="display: block; width:0.2rem; height:0.2rem;" src="../assets/correct.png">
            </div>
            <div v-if="item.selected && item.ref_pos<0" style="position: absolute;top: 0rem;right: 0rem;">
              <img style="display: block; width:0.2rem; height:0.2rem;" src="../assets/wrong.png">
            </div>
          </div>
        </div>
      </div>
      <div v-if="infoArr[currId].question.type=='排序题'">
        <div style="margin-left:0.15rem;padding:0.1rem;min-height:0.5rem;font-size:0.16rem;border-radius: 0.1rem;border: 0.01rem solid #EEEEEE;">
          <div v-if="infoArr[currId].question.type2text.length==0" style="color:#cccccc;">点击后的单词将会按顺序出现在这里</div>
          <div v-else style="color:#222222;font-weight: 450;line-height: 0.23rem;">{{infoArr[currId].question.type2text}}</div>
        </div>
        <div  class="horizontal" style="flex-wrap: wrap;">
          <div v-for="(item,index) in infoArr[currId].question.option2" :key="index" @click="answer(currId,index,true)">
            <div class="word" :style="{'padding':'0.12rem 0.2rem','background': item.color}">
              <div>{{item.word}}</div>
              <div v-if="item.selected" style="position: absolute;top: 0rem;right: 0rem;">
                <img style="display: block; width:0.2rem; height:0.2rem;" src="../assets/correct.png">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="padding-bottom:1.48rem;"></div>
    
    <div class="bottomarea">
      <div class="horizontal" style="padding:0.15rem;">
        <div class="textprogress">{{progressstr}}</div>
        <van-slider class='sliderprogress' active-color='#009FF8' button-size='0.1rem' v-model="progressvalue" @change="onProgressChange" 
        @drag-start="dragStart" @drag-end="dragEnd"/>
        <div class="textprogress">{{durationstr}}</div>
      </div>
      <div class="horizontal" style="padding:0.15rem 0.15rem 0.3rem 0.15rem;">
        <div class="replaystyle">
          <div><img class="replayicon" src="../assets/relisten.png" alt="" @click="rePlayMode()"></div>
          <div style="width:0.01rem;height:0.15rem;background: #BBBBBB;margin-right:0.05rem;"></div>
          <div v-if="clearAnswer" style="color:#666666;font-size:0.14rem;" @click="replay()">重新练</div>
          <div v-else style="color:#666666;font-size:0.14rem;" @click="replay()">从头听</div>
        </div>

        <div><img class='playbutton' v-bind:src="playicon" @click="playAudio" alt=""></div>
        <div v-if="currId>=0 && infoArr[currId].question.type2idx>0" class="nextstyle" @click="nextTrainStep()">
          <div style="color:#009FF8;font-size:0.14rem;font-weight: bold;">
            <div v-if="currId<infoArr.length-1">确定</div>
            <div v-else-if="albumID=='S00I'">提交</div>
            <div v-else-if="show_mode==1">完成</div>
            <div v-else>下一步</div>
          </div>
          <div><img class="nexticon" src="../assets/nextstep.png" alt=""></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import axios from 'axios'
import {Howl} from 'howler'

export default {
  data() {
    return {
      userID: "",
      albumID:'',
      storyID:'',
      crossInfo:{},

      show_mode:0,
      os_type:"",

      showtips:false,
      infoArr:[],
      currId:-1,
      learn_second:0,

      clearAnswer:true,

      playicon: require('../assets/audio-icon-play.png'),
      sound: null,

      progressstr:"00:00",
      durationstr:"00:00",
      progressvalue:0,
      touching:false
    };
  },
  created() {
    document.title = '单词闯关'
    this.getRouterData()
  },
  beforeDestroy() {
    this.sound && this.sound.stop()
  },
  mounted(){
    const that = this
    window.autoPlay = that.autoPlay
    window.finish = that.finish
    window.refresh = that.refresh
  },
  
  methods: {
    async getRouterData() {
      console.log("route",window.location.href)
      let params = JSON.parse(this.$route.query.params)
      this.userID = params.userID
      this.albumID = params.albumID
      this.storyID = params.storyID
      this.crossInfo = params.crossInfo
      if(window.location.href.includes('os_type=')) this.os_type=this.$route.query.os_type
      if(window.location.href.includes('show_mode=')) this.show_mode=this.$route.query.show_mode

      let request_data = {
        'type' : 'geci_data',
        'data' : {
          'user_id':this.userID,
          'album_id':this.albumID,
          'story_id':this.storyID
        }
      }
      await axios.post('https://caixiaowen.com/tongtu',JSON.stringify(request_data))
      .then((result) =>{
        console.log(result.data)
        this.wav_url = result.data.story_data.wav

        let geciInfo=result.data.geci_data
        let choiceLst=result.data.history.choice_list
        
        var d={}
        for(var i=0;i<geciInfo.sent_list.length;i++){
          if(Object.prototype.hasOwnProperty.call(geciInfo.sent_list[i],'question')){
            if(i>0){
              d.ed_sec=geciInfo.sent_list[i].time_start
              this.infoArr.push(d)
            }
            d={
              text:'',
              st_sec:geciInfo.sent_list[i].time_start,
              ed_sec:10000,
              question:geciInfo.sent_list[i].question,
              right_num:0,
              wrong_num:0
            }
            if(i==0) d.st_sec=0
            d['question']['option2']=[]
            var option0=d.question.option.split(';')
            var answer0=d.question.answer.split(';')
            for(var j=0;j<option0.length;j++){
              var right=-1
              for(var k=0;k<answer0.length;k++){
                if(option0[j]==answer0[k])right=k
              }
              d['question']['option2'].push({
                'word':option0[j],
                'ref_pos':right,
                'selected':false,
                'color':'rgba(153,153,153,0.08)'
              })
            }
            d.question.answer=answer0
            d['question']['type2text']=''
            d['question']['type2idx']=0
          }
          d.text+=geciInfo.sent_list[i].text_en+"<br/>";
        }
        this.infoArr.push(d)
        //console.log(this.infoArr)

        for(i=0;i<this.infoArr.length;i++){
          let choices=[]
          for(j=0;j<choiceLst.length;j++){
            if(choiceLst[j].no==i){
              choices=choiceLst[j].choices
              break
            }
          }

          let d=this.infoArr[i].question.option2
          if(this.infoArr[i].question.type=="排序题"){
            var tl=new Array(100).fill(-1)
            for(var m=0;m<choices.length;m++){
              for(var n=0;n<d.length;n++){
                if(choices[m]==d[n].word){
                  tl[d[n].ref_pos]=n
                  break
                }
              }
            }
            for(m=0;m<100;m++){
              if(tl[m]==-1) break
              this.answer(i,tl[m],false)
            }
          }
          else {
            for(m=0;m<choices.length;m++){
              for(n=0;n<d.length;n++){
                if(choices[m]==d[n].word){
                  this.answer(i,n,false)
                  break
                }
              }
            }
          }
        }

        this.currId=0
        if(this.os_type=='ios') {
          window.webkit.messageHandlers.autoPlay.postMessage(null)
        }
        else {
          this.playAudio()
        }
      }).catch((error) =>{
        console.log(error)
      })
    },
    refresh(){
      //
    },
    finish(){
      this.sound && this.sound.stop()
      this.sound=null
    },
    autoPlay(){
      this.playAudio()
    },

    getPosition() {
      var duration = this.sound.duration();
      if(duration>0) {
        var soundseek=this.sound.seek()
        this.progressvalue = soundseek*100/duration;

        if(soundseek>=this.infoArr[this.currId].ed_sec){
          this.sound.pause()
        }
        if(duration<=0) return

        var t1=parseInt(soundseek/60)
        if(t1<10)t1='0'+t1
        var t2=parseInt(soundseek%60)
        if(t2<10)t2='0'+t2
        var t3=parseInt(duration/60)
        if(t3<10)t3='0'+t3
        var t4=parseInt(duration%60)
        if(t4<10)t4='0'+t4
        this.progressstr = ''+t1+':'+t2
        this.durationstr = ''+t3+':'+t4
      }

      var s = this.sound.playing()
      if(!s) {
        this.playicon=require('../assets/audio-icon-play.png');
      }
      if( s && !this.touching) {
        requestAnimationFrame(this.getPosition);
      }
    },
    calLearnSecond(){
      if(this.infoArr[this.currId].ed_sec>=10000){
        this.infoArr[this.currId].ed_sec = this.sound.duration()
      }
      this.learn_second += this.infoArr[this.currId].ed_sec-this.infoArr[this.currId].st_sec
      console.log(this.learn_second)
    },
    playAudio() {
      if(this.sound===null) {
        console.log("initial sound",this.wav_url)
        this.sound = new Howl({
          src: [this.wav_url],

          onplay:() => {
            console.log("开始播放啦")
            this.playicon=require('../assets/audio-icon-pause.png');
            requestAnimationFrame(this.getPosition);
            this.calLearnSecond()
          },
          onloaderror:() => {
            Toast("声音导入失败")
          },
          onplayerror:() => {
            Toast("声音播放失败")
          },
        })
      }
      this.sound.seek(this.infoArr[this.currId].st_sec)
      this.sound[this.sound.playing()? 'pause' : 'play']()
    },
    rePlayMode(){
      this.clearAnswer = !this.clearAnswer
    },
    replay(){
      if(this.clearAnswer){
        for(var i=0;i<this.infoArr.length;i++){
          for(var j=0;j<this.infoArr[i].question.option2.length;j++){
            this.infoArr[i].question.option2[j].selected=false
            this.infoArr[i].question.option2[j].color='rgba(153,153,153,0.08)'
          }
          this.infoArr[i].question.type2text=''
          this.infoArr[i].question.type2idx=0
          this.infoArr[i].right_num=0
          this.infoArr[i].wrong_num=0
        }
        console.log('clear answer')
        //this.clearAnswer=false
      }
      //
      this.currId=0
      this.showtips=false

      if(this.sound!=null){
        console.log('replay')
        this.sound.seek(0)
        if(!this.sound.playing()){
          this.sound.play()
        }else {
          this.calLearnSecond()
        }
      }
    },
    
    onProgressChange() {
      /*
      var sec=this.sound?this.progressvalue*this.sound.duration()/100:0
      console.log("change to",this.progressvalue,sec)
      
      this.sound && this.sound.seek(sec)
      */
    },
    dragStart() {
      //this.touching = true;
    },
    dragEnd() {
      /*
      this.touching = false;
      if(this.sound!=null) {
        requestAnimationFrame(this.getPosition);
      }
      */
    },
    
    answer(no,index,is_click){
      let d=this.infoArr[no].question.option2

      console.log(this.infoArr[no].question.type)
      if(this.infoArr[no].question.type=="排序题"){
        if(d[index].ref_pos==this.infoArr[no].question.type2idx){
          this.infoArr[no].question.type2text += d[index].word+' '
          this.infoArr[no].question.type2idx++
          this.infoArr[no].right_num+=1
          d[index].selected=true
          d[index].color='rgba(79,211,127,0.08)'
          console.log('good choice type=2')

          if(is_click){
            let voicemp3=require('../assets/great.mp3')
            new Howl({src: [voicemp3],}).play()

            if(this.infoArr[no].right_num==this.infoArr[no].question.answer.length){
              Toast("全部正确")
            }
          }
        }
        else if(!d[index].selected){
          d[index].color='rgba(255,107,84,0.08)'
          var that=this
          setTimeout(function(){
            that.infoArr[no].question.option2[index].color='rgba(153,153,153,0.08)'
          },1000)
        }
        return
      }
      
      if(d[index].selected){
        d[index].selected=false
        d[index].color='rgba(153,153,153,0.08)'
        this.infoArr[no].question.type2idx--
        console.log('cancle')

        if(d[index].ref_pos>=0){
          this.infoArr[no].right_num-=1
        } else {
          this.infoArr[no].wrong_num-=1
        }
        if(is_click && this.infoArr[no].wrong_num==0
          && this.infoArr[no].right_num==this.infoArr[no].question.answer.length){
          Toast("全部正确")
        }
      }
      else if(d[index].ref_pos>=0){
        d[index].selected=true
        d[index].color='rgba(79,211,127,0.08)'
        this.infoArr[no].question.type2idx++
        this.infoArr[no].right_num+=1
        console.log('good choice')

        if(is_click){
          let voicemp3=require('../assets/great.mp3')
          new Howl({src: [voicemp3],}).play()

          if(this.infoArr[no].wrong_num==0
            && this.infoArr[no].right_num==this.infoArr[no].question.answer.length){
            Toast("全部正确")
          }
        }
      }
      else {
        d[index].selected=true
        d[index].color='rgba(255,107,84,0.08)'
        this.infoArr[no].question.type2idx++
        this.infoArr[no].wrong_num+=1
        console.log('bad choice')
      }
    },
    nextTrainStep(){
      var choices=[]
      let d=this.infoArr[this.currId].question.option2
      for(var i=0;i<d.length;i++){
        if(d[i].selected) choices.push(d[i].word)
      }

      let request_data = {
        'type' : 'save_exercise_data',
        'data' : {
          'user_id':this.userID,
          'album_id':this.albumID,
          'story_id':this.storyID,
          'data_type':'word_express',
          'learn_time':this.learn_second,
          'data':{
            'no':this.currId,
            'choices':choices
          }
        }
      }
      this.learn_second=0
      axios.post('https://caixiaowen.com/tongtu',JSON.stringify(request_data))
      .then((result) =>{
        console.log('答案上传成功',result.data)
      }).catch((error) =>{
        console.log(error)
      })

      if(this.currId<this.infoArr.length-1){
        this.currId++
        this.showtips=false
        
        this.sound.seek(this.infoArr[this.currId].st_sec)
        if(!this.sound.playing()){
          this.sound.play()
        }else {
          this.calLearnSecond()
        }
      }
      else if(this.currId==this.infoArr.length-1 && this.albumID=='S00I'){
        this.sound && this.sound.stop()
        
        request_data = {
          'type' : 'report_data',
          'data' : {
            'user_id':this.userID,
            'story_id':this.storyID,
            'update':true,

            'album_id':this.albumID,
            'sub_album_id':this.crossInfo.sub_album_id,
            'story_no':this.crossInfo.train_story_no
          }
        }
        axios.post('https://caixiaowen.com/tongtu',JSON.stringify(request_data))
        .then(() =>{
          Toast("提交成功")
        })
      }
      else if(this.currId==this.infoArr.length-1){
        this.sound && this.sound.stop()

        if(this.show_mode==1){
          Toast("全部完成了")
          return
        }
        
        let params = JSON.stringify({
          userID:this.userID,
          albumID:this.albumID,
          storyID:this.storyID,
          crossInfo:this.crossInfo
        })
        let json_param = {
          'url_param':"video?params=" + params + '&os_type=' + this.os_type,
          'title':"实战讲解"
        }

        if(this.os_type=="android"){
          window.android.gotoDetail(JSON.stringify(json_param))
        }
        else if (this.os_type=="ios"){
          window.webkit.messageHandlers.gotoDetail.postMessage(json_param)
        }
        else{
          this.$router.push({
            name: 'video',
            query: {params}
          })
        }
      }
    },
    showtext(){
      this.showtips=!this.showtips
    }
  }
};
</script>

<style scoped>
#expresswords {
  min-height: 100vh;
  height:auto;
}

#expresswords .texttips{
  width:3.45rem;
  min-height:1.8rem;
  max-height:3.2rem;
  margin:0.15rem;
  background:#DBF2FF;
  border-radius: 0.2rem;
  align-items: center;
  overflow: auto;
}

#expresswords .tipsimage1{
  display: block;
  width:0.32rem;
  height:0.24rem;
  margin:auto;
  padding-top:0.55rem;
}

#expresswords .tipsimage2{
  display: block;
  width:0.24rem;
  height:0.18rem;
  padding-left:0.15rem;
  padding-top:0.2rem;
}

#expresswords .word {
  color:#222222;
  font-weight: 450;
  font-size:0.16rem;
  text-align:center;

  position: relative;

  margin-left: 0.15rem;
  margin-top: 0.15rem; 
  
  padding:0.12rem 0.1rem;
  border-radius: 0.15rem;
}

#expresswords .horizontal {
  flex-direction: row;
  display: flex;
  position: relative;
}

#expresswords .bottomarea {
  position: fixed;
  width:100%;
  bottom: 0rem;
  background:white;
}

#expresswords .sliderprogress {
  width: 2.4rem;
  margin:auto;
}
#expresswords .textprogress {
  font-size: 0.12rem;
  color:#666666;
  margin:auto;
}
#expresswords .replaystyle{
  flex-direction: row;
  display: flex;
  position: relative;

  background:rgba(153,153,153,0.08);
  padding:0.08rem 0.15rem;
  margin:0.1rem;
  border-radius: 0.18rem;
  align-items: center;
}
#expresswords .nextstyle{
  flex-direction: row;
  display: flex;
  position: relative;

  background:rgba(0,159,248,0.08);
  padding:0.08rem 0.15rem;
  margin:0.1rem 0.1rem 0.1rem 0.45rem;
  border-radius: 0.18rem;
  align-items: center;
}
#expresswords .replayicon {
  display: block;
  width: 0.14rem;
  height: 0.14rem;
  margin-right: 0.06rem;
}
#expresswords .nexticon {
  display: block;
  width: 0.06rem;
  height: 0.1rem;
  margin-left: 0.06rem;
}
#expresswords .playbutton {
  display: block;
  width: 0.55rem;
  height: 0.55rem;
  margin-left:0.27rem;
}

</style>


<template>
  <div class="" id="plan">
    <van-calendar
      ref="calendar"
      :show-title='false'
      :min-date="minDate"
      row-height="0.4rem"
      :poppable="false"
      :first-day-of-week="1"
      :formatter="formatter"
      :show-confirm="false" 
      :show-mark="false"
      @select="selectDay"
      :style="{ height:'2.7rem'}" />

      <div v-if="user_status==0" style="margin:0.15rem;background:white;border-radius: 0.15rem;min-height: 1rem;">
        <div style="font-size:0.20rem;color:#bbbbbb;text-align: center;padding-top:0.4rem;">你还没有登录！</div>
      </div>

    <div v-if="user_status==2" style="font-size:0.16rem;font-weight: bold;color: #222222;margin-top:0.3rem;margin-left:0.2rem;">{{dayTips}}</div>

    <div v-if="user_status==2" style="margin:0.15rem;background:white;border-radius: 0.15rem;min-height: 1rem;">
      <div v-if="loading" style="font-size:0.20rem;color:#bbbbbb;text-align: center;padding-top:0.4rem;">正在查询学习记录</div>
      <div v-else-if="storylist.length==0" style="font-size:0.20rem;color:#bbbbbb;text-align: center;padding-top:0.4rem;">没有学习记录</div>
      <div v-for="(item,index) in storylist" :key="index">
        <div class="horizontal" style="background:white;align-items: center;" @click="checkReporter(index)">
          <div class="seri" :style="{'background':'url('+item.color+')'}">
            {{index<9?'0':''}}{{index+1}}
          </div>
          <div style="width:2.4rem;margin-left:0.1rem;">
            <div class="title" style="padding: 0.08rem 0;font-size:0.15rem;font-weight: bold;">{{item.title_en}}</div>
            <div class="title">{{item.album_name}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { Toast } from 'vant';

export default {
  data() {
    return {
      userID: "",
      user_status:0,//0:未登录或者账号不存在，1:已登录但未激活课程，2:已登录并激活课程

      os_type:"",

      day2storylist :{},
      storylist: [],

      dayTips:'今日学习',
      loading:false,
      
      //calendar
      minDate: new Date(2022, 5, 1)
    };
  },
  created() {
    document.title = '通途英语'
    this.getRouterData()
  },
  methods: {
    getRouterData() {
      console.log("plan-route",window.location.href)
      this.userID = this.$route.query.userID
      if(window.location.href.includes('os_type=')) this.os_type=this.$route.query.os_type

      if(this.userID.length>0){
        this.user_status = 2
        this.getReviewData(null)
      }
    },

    async getReviewData(date0){
      if(this.loading) return
      this.loading=true

      var request_data = {
        'type' : 'review_story_list',
        'data' : {
          'user_id':this.userID
        }
      }
      if(date0!=null) request_data['data']['date']=date0
      
      await axios.post('https://caixiaowen.com/tongtu',JSON.stringify(request_data))
      .then((result) =>{
        console.log(result.data)

        var tlst = result.data['story_list']
        for(var i=0;i<tlst.length;i++){
          let date=tlst[i].date
          if(!Object.prototype.hasOwnProperty.call(this.day2storylist,date)){
            this.day2storylist[date]=[]
          }

          let albumID=tlst[i].story_id.substr(0,4)
          if(albumID=="S00E"){
            tlst[i].color=require('../assets/color3.png')
            tlst[i].album_name="主题课"
            tlst[i].from_type=1
          }
          else if(albumID=="S00G"){
            tlst[i].color=require('../assets/color4.png')
            tlst[i].album_name="跟读课"
            tlst[i].from_type=2
          }
          else if(albumID=="S00H"){
            tlst[i].color=require('../assets/color5.png')
            tlst[i].album_name="看图表达"
            tlst[i].from_type=2
          }
          else if(albumID=="S00I"){
            tlst[i].color=require('../assets/color1.png')
            tlst[i].album_name="听力课"
            tlst[i].from_type=-1
          }
          this.day2storylist[date].push(tlst[i])
        }
        this.selectDay(new Date())
        this.$refs.calendar.reset()
        this.loading=false
      }).catch((error) =>{
        console.log(error)
        this.loading=false
      })
    },

    selectDay(day) {
      const year0 = day.getFullYear();
      const month0 = (day.getMonth() + 1).toString().padStart(2,'0');
      const date0 = (day.getDate()).toString().padStart(2,'0');
      const tday = year0 + "-" + month0 + "-" + date0

      if(Object.prototype.hasOwnProperty.call(this.day2storylist,tday)){
        this.storylist = this.day2storylist[tday].slice()
      }
      else {
        this.storylist = []
      }

      var now = new Date()
      const year1 = now.getFullYear();
      const month1 = (now.getMonth() + 1).toString().padStart(2,'0');
      const date1 = (now.getDate()).toString().padStart(2,'0');
      
      if(year0==year1 && month0==month1 && date0==date1){
        this.dayTips='今日学习'
      }
      else {
        this.dayTips=month0+'月'+date0+'日学习'
      }
    },
    
    formatter(day) {
      const year = day.date.getFullYear();
      const month = (day.date.getMonth() + 1).toString().padStart(2,'0');
      const date = (day.date.getDate()).toString().padStart(2,'0');
      const tday = year + "-" + month + "-" + date

      var now = new Date()
      const year1 = now.getFullYear();
      const month1 = (now.getMonth() + 1).toString().padStart(2,'0');
      const date1 = (now.getDate()).toString().padStart(2,'0');

      if(year==year1 && month==month1 && date==date1){
        day.text = '今天'
      }

      if(Object.prototype.hasOwnProperty.call(this.day2storylist,tday)){
        day.className='daycolor1'
      }
      else {
        day.className='daycolor0'
      }
      return day;
    },
    checkReporter(index){
      if(this.storylist[index]['from_type']==-1){
        Toast("听力课没有学习报告哟")
        return
      }

      let params = JSON.stringify({
        userID:this.userID,
        storyID:this.storylist[index]['story_id'],
        from_type:this.storylist[index]['from_type']
      })
      let json_param = {
        'url_param':"reportcard?params=" + params + '&os_type=' + this.os_type,
        'title':""
      }
      if(this.os_type=="android"){
        window.android.gotoDetail(JSON.stringify(json_param))
      }
      else if(this.os_type=="ios"){
        window.webkit.messageHandlers.gotoDetail.postMessage(json_param)
      }
      else{  
        this.$router.push({
          name: 'reportcard',
          query: {params}
        })
      }
    }
  },
};
</script>

<style scoped>

#plan {
  min-height: 100vh;
  height:auto;
  background: #F5F6F7;
}

/deep/ .daycolor0{
  color:#BBBBBB;
}

/deep/ .daycolor1{
  color:black; 
}

#plan .horizontal {
  flex-direction: row;
  display: flex;
  position: relative;
}
#plan .title{
  font-size: 0.12rem;
  color:#444444;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

#plan .seri{
  border-radius: 0.3rem;
  font-size: 0.15rem;
  text-align: center;
  color: white;
  padding:0.15rem;
  margin:0.1rem 0.1rem 0.1rem 0.2rem;
  position: relative;
}
</style>

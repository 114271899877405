
<template>
  <div id="marketshow"> 
    <div v-if="saleType<1000">
      <img style="display:block;width:100%;" src="../assets/sales0/pic1.jpg" alt="">
      <img style="display:block;width:100%;" src="../assets/sales0/pic2.jpg" alt="">
      <img style="display:block;width:100%;" src="../assets/sales0/pic3.jpg" alt="">
      <div style="position: relative;">
        <img style="display:block;width:100%;" src="../assets/sales0/pic4.jpg" alt="">
        <div style="position: absolute;top: 0.9rem;left: 0.3rem;" @click="orderType=103;">
          <img v-if="orderType==103" style="display: block; width:1rem; height:1.22rem;" src="../assets/sales0/price1_2.jpg">
          <img v-else style="display: block; width:1rem; height:1.22rem;" src="../assets/sales0/price1.jpg">
        </div>
        <div style="position: absolute;top: 0.9rem;left: 1.37rem;" @click="orderType=100;">
          <img v-if="orderType==100" style="display: block; width:1rem; height:1.22rem;" src="../assets/sales0/price6_2.jpg">
          <img v-else style="display: block; width:1rem; height:1.22rem;" src="../assets/sales0/price6.jpg">
        </div>
        <div style="position: absolute;top: 0.9rem;right: 0.3rem;" @click="orderType=101;">
          <img v-if="orderType==101" style="display: block; width:1rem; height:1.22rem;" src="../assets/sales0/price12_2.jpg">
          <img v-else style="display: block; width:1rem; height:1.22rem;" src="../assets/sales0/price12.jpg">
        </div>
      </div>
      <div class="paybutton1" @click="pay">
        <div style="font-size: 0.24rem;color: white;text-align: center;padding: 0.1rem;">
          立即开通
          <!--{{ os_type=="android"?"联系客服报名":"立即开通"}}-->
        </div>
      </div>
    </div>
    <div v-else-if="saleType<2000">
      <img style="display:block;width:100%;" src="../assets/sales2/pic1.jpg" alt="">
      <img style="display:block;width:100%;" src="../assets/sales2/pic2.jpg" alt="">
      <img style="display:block;width:100%;" src="../assets/sales2/pic3.jpg" alt="">
      <img style="display:block;width:100%;" src="../assets/sales2/pic4.jpg" alt="">
      <img style="display:block;width:100%;" src="../assets/sales2/pic5.jpg" alt="">
      <img style="display:block;width:100%;" src="../assets/sales2/pic6.jpg" alt="">
      <img style="display:block;width:100%;" src="../assets/sales2/pic7.jpg" alt="">
      <img style="display:block;width:100%;" src="../assets/sales2/pic8.jpg" alt="">
      <img style="display:block;width:100%;" src="../assets/sales2/pic9.jpg" alt="">
      <img style="display:block;width:100%;" src="../assets/sales2/pic10.jpg" alt="">
      <div class="paybutton1" @click="pay">
        <div style="font-size: 0.24rem;color: white;text-align: center;padding: 0.1rem;">
          领取优惠
        </div>
      </div>
    </div>
    <div v-if="verify_view" class="bottomarea">
      <img style="display: block; width:0.2rem;height:0.2rem;padding:0.2rem; float:right;" src="../assets/close_x.png" @click="verify_view=false;wait_time=0">
      <div style="font-size:0.15rem;color: #999999;padding:0.2rem;">使用手机号注册后购买, 在App内获取会员课程</div>
      <div style="font-size: 0.18rem;margin:0 0.2rem;">
        <input ref="phone_ref" type="number" style="border-style: dotted;padding: 0.1rem;width:3.15rem;height: 0.22rem;" placeholder="请输入手机号">
      </div>
      <div style="flex-direction: row;display: flex;position: relative; align-items: center; font-size: 0.18rem;margin: 0.1rem 0.2rem;">
        <input ref="verify_code_ref" type="number" style="border-style: dotted;padding:0.1rem;width:2rem;height:0.22rem;" placeholder="请输入验证码">
        <div style="padding-left:0.15rem;color:lightblue;font-size:0.16rem;" @click="phone_register()">
          <span v-if="wait_time>0">重新发送 {{ wait_time }}</span>
          <span v-else>获取验证码</span>
        </div>
      </div>
      <div style="margin:0.2rem 0.5rem;padding:0.12rem;background: green;border-radius: 0.2rem;" @click="login()">
        <div style="font-size: 0.18rem;color: white;text-align: center;">注册</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { Toast } from 'vant';

export default {
  data() {
    return {
      userID: '',
      openID: '',
      os_type: '',

      verify_view: false,
      verify_code:'',
      verify_success:false,
      wait_time:0,

      saleType: 0,
      orderType:101,
      outDate:1
    };
  },
  created() {
    document.title = '通途英语'
    this.getRouterData()
  },
  mounted(){
    const that = this
    window.pay = that.pay
    window.doLogin = that.doLogin
  },
  methods: {
    async getRouterData() {
      console.log("route", window.location.href)
      this.userID=this.$route.query.userID
      this.saleType = this.$route.query.type
      if (window.location.href.includes('os_type=')) this.os_type = this.$route.query.os_type
      if (window.location.href.includes('openID=')) this.openID = this.$route.query.openID

      if(this.userID!=""){
        let request_data = {
          'type' : 'order_info',
          'data' : {
            "user_id":this.userID
          }
        }
        const result = await axios.post('https://caixiaowen.com/tongtu',JSON.stringify(request_data))
        this.outDate = result.data.out_date
      }
    },
    pay(){
      let json_param = {
        'sale_type': '' + this.saleType,
        'order_type': '' + this.orderType,
        'out_date': '' + this.outDate
      }
      //
      if(this.os_type=="android"){
        if(this.userID==""){
          window.android.doLogin("")
        } else {
          window.android.pay(JSON.stringify(json_param))
        }
      }
      else if (this.os_type=="ios"){
        if(this.userID==""){
          window.webkit.messageHandlers.doLogin.postMessage(null)
        } else {
          window.webkit.messageHandlers.pay.postMessage(json_param)
        }
      }
      else if(this.$root.wxConfigUrl != "" && this.openID.length!=0){
        if(this.verify_success){
          this.wxpay_jsapi_test()
        } else {
          this.verify_view = true
        }
      } else {
        Toast("请联系客服购买哦!")
      }
    },

    async login() {
      let verify_code_input = this.$refs.verify_code_ref.value
      if(this.verify_code!='' && verify_code_input==this.verify_code){
        this.verify_view = false
        this.verify_success = true
        Toast("恭喜你,注册成功")

        let request_data = {
          'type' : 'order_info',
          'data' : {
            "user_id":this.userID
          }
        }
        const result = await axios.post('https://caixiaowen.com/tongtu',JSON.stringify(request_data))
        this.outDate = result.data.out_date
        //
      } else if(verify_code_input.length==0) {
        Toast("请输入验证码")
      } else {
        Toast("验证码输入错误")
      }
    },
    verify_code_waiting(){
      let that = this
      setTimeout(function() {
        that.wait_time-=1
        if(that.wait_time>0) that.verify_code_waiting()
      }, 1000)
    },
    async phone_register(){
      if(this.wait_time>0) return

      let phonenum = this.$refs.phone_ref.value
      if(phonenum.length!=11){
        Toast("手机号码无效")
        return
      }
      let request_data = {
        'type': 'phone_register',
        'data': {
          'phone_number': phonenum,
          'send_sms':1
        }
      }
      await axios.post('https://caixiaowen.com/tongtu', JSON.stringify(request_data))
        .then((result) => {
          console.log(result.data)
          if(result.data.err_no==0){
            this.userID = result.data.user_id
            this.verify_code = result.data.verify_code

            this.wait_time=60
            this.verify_code_waiting()
          }
        }).catch((error) =>{
          console.log(error)
        })
    },

    async wxpay_jsapi_test() {
      if(this.outDate==0){
        Toast("你已经购买了课程,请前往App学习吧")
        return
      }
      await axios.get('https://caixiaowen.com/pay_jsapi?openID='+this.openID+"&userID="+this.userID+"&orderType="+this.orderType+"&saleType="+this.saleType)
        .then((result) => {
          window.WeixinJSBridge.invoke('getBrandWCPayRequest', result.data.result,
            function (res) {
              if (res.err_msg == "get_brand_wcpay_request:ok") {
                console.log("微信支付成功")
              }
            });
        }).catch((error) => {
          console.log(error)
        })
    }
  }
};
</script>

<style scoped>
#marketshow .paybutton1 {
  background: url("../assets/sales0/pay_icon.jpg");
  background-size: cover;
  margin:0.2rem;
  width: 3.35rem;
  height: 0.5rem;
}
#marketshow .bottomarea {
  position: fixed;
  width: 100%;
  bottom: 0rem;
  background: white;
  opacity: 0.95;
  border-radius: 0.1rem;
}
</style>


<template>
  <div id="knowlevideo">
    <div class="video" style="padding-bottom:1.4rem;">
      <video-player class="video-player vjs-custom-skin"
        ref="videoPlayer" 
        :playsinline="true" 
        @play="onPlayerPlay($event)"
        @pause="onPlayerPause($event)"
        @ended="onPlayerEnded($event)"
        @timeupdate="onPlayerTimeupdate($event)"
        :options="playerOptions">
      </video-player>
    </div>

    <div class="bottomarea">
      <div class="horizontal" style="padding:0.15rem;">
        <div class="textprogress">{{progressstr}}</div>
        <van-slider class='sliderprogress' active-color='#009FF8' button-size='0.1rem' v-model="progressvalue" @change="onProgressChange" 
        @drag-start="dragStart" @drag-end="dragEnd"/>
        <div class="textprogress">{{durationstr}}</div>
      </div>
      <div class="horizontal" style="padding:0.15rem 0.15rem 0.3rem 0.15rem;">
        <div class="replaystyle" style="width:0.65rem;" @click="store()">
          <div v-if="!crossInfo.video_store_status"><img class="replayicon" src="../assets/shoucang1.png" alt=""></div>
          <div v-else><img class="replayicon" src="../assets/shoucang2.png" alt=""></div>
          <div v-if="!crossInfo.video_store_status" style="color:#666666;font-size:0.14rem;">收藏</div>
          <div v-else style="color:#666666;font-size:0.14rem;">已收藏</div>
        </div>
        <div><img class='playbutton' v-bind:src="playicon" @click="playVideo" alt=""></div>
        <div class="nextstyle" @click="nextTrainStep()">
          <div style="color:#009FF8;font-size:0.14rem;font-weight: bold;">下一步</div>
          <div><img class="nexticon" src="../assets/nextstep.png" alt=""></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { Toast } from 'vant'
import axios from 'axios'
import { Toast } from 'vant';

export default {
  data() {
    return {
      userID: "",
      albumID:'',
      storyID:'',
      crossInfo:{},

      learn_second:0,
      last_time_pos:0,

      os_type:"",

      playerOptions : {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: true, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '3:4', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [],
        poster: require("../assets/cover_video.jpg"), //你的封面地址
        // width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        flash: { hls: { withCredentials: false } },
        html5: { hls: { withCredentials: false } },
        controls: false,
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
          remainingTimeDisplay: false,
          fullscreenToggle: true  //全屏按钮
        }
      },
      playStatus:-1,

      playicon: require('../assets/audio-icon-play.png'),

      durationvalue:0,
      durationstr:"00:00",
      progressvalue:0,
      progressstr:"00:00",
      touching:false
    };
  },
  created() {
    document.title = '通途英语'
    this.getRouterData()
  },
  beforeDestroy() {
    //
  },
  methods: {
    getRouterData() {
      console.log("route",window.location.href)
      let params = JSON.parse(this.$route.query.params)
      this.userID = params.userID
      this.albumID = params.albumID
      this.storyID = params.storyID
      this.crossInfo = params.crossInfo
      if(window.location.href.includes('os_type=')) this.os_type=this.$route.query.os_type

      this.playerOptions.sources=[{
        type: "video/mp4",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
        src: this.crossInfo.video_url //url地址
      }]
    },

    onPlayerPlay(player) {
      console.log('player play!', player)
      this.playStatus=1
      this.playicon=require('../assets/audio-icon-pause.png');
    },
 
    // 暂停回调
    onPlayerPause(player) {
      console.log('player pause!', player)
      this.playStatus=0
      this.playicon=require('../assets/audio-icon-play.png');
    },
 
    // 视频播完回调
    onPlayerEnded(player) {
      console.log('player ended',player)
      this.playStatus=-1
      this.playicon=require('../assets/audio-icon-play.png');
    },
    
    onPlayerTimeupdate(player) {
      var duration = player.cache_.duration
      var soundseek = player.cache_.currentTime

      if(this.touching || duration<=0) return
      
      if(soundseek>this.last_time_pos && soundseek<this.last_time_pos+3){
        this.learn_second += soundseek-this.last_time_pos
      }
      this.last_time_pos = soundseek

      this.progressvalue = soundseek*100/duration
      this.durationvalue = duration

      var t1=parseInt(soundseek/60)
      if(t1<10)t1='0'+t1
      var t2=parseInt(soundseek%60)
      if(t2<10)t2='0'+t2
      var t3=parseInt(duration/60)
      if(t3<10)t3='0'+t3
      var t4=parseInt(duration%60)
      if(t4<10)t4='0'+t4
      this.progressstr = ''+t1+':'+t2
      this.durationstr = ''+t3+':'+t4
    },
    
    playVideo() {
      if(this.playStatus==-1 || this.playStatus==0){
        this.$refs.videoPlayer.player.play()
      }
      else {
        this.$refs.videoPlayer.player.pause()
      }
    },
    onProgressChange() {
      var sec=this.progressvalue*this.durationvalue/100.0
      console.log("change to",this.progressvalue,sec)

      this.$refs.videoPlayer.player.currentTime(sec)
    },
    dragStart() {
      this.touching = true;
    },
    dragEnd() {
      this.touching = false;
    },

    store(){
      if(this.albumID=="S00F"){
        Toast('体验课无法收藏')
        return
      }
      this.crossInfo.video_store_status=!this.crossInfo.video_store_status

      let request_data = {
        'type' : 'story_recommend',
        'data' : {
          'user_id':this.userID,
          'record_list': [{
              'story_id':this.storyID,
              'like_num':0,
              'store_num':this.crossInfo.video_store_status?1:-1,
              'click_num':1
            }
          ],
          'need_story_num': 0,
          'album_id':this.albumID,
          'first_time' : true
        }
      }
      axios.post('https://caixiaowen.com/tongtu',JSON.stringify(request_data))
      .then((result) =>{
        console.log('收藏状态上传成功',result.data)
      }).catch((error) =>{
        console.log(error)
      })
    },
    
    nextTrainStep(){
      let request_data = {
        'type' : 'save_exercise_data',
        'data' : {
          'user_id':this.userID,
          'album_id':this.albumID,
          'story_id':this.storyID,
          'data_type':'knowledge_video',
          'learn_time':this.learn_second,
          'data':{
            'no':0
          }
        }
      }
      this.learn_second=0
      axios.post('https://caixiaowen.com/tongtu',JSON.stringify(request_data))
      .then((result) =>{
        console.log('答案上传成功',result.data)
      }).catch((error) =>{
        console.log(error)
      })

      let params = JSON.stringify({
        userID:this.userID,
        albumID:this.albumID,
        storyID:this.storyID,
        crossInfo:this.crossInfo
      })
      let json_param = {
        'url_param':"gendu2?params=" + params + '&os_type=' + this.os_type,
        'title':"口语测评"
      }
      if(this.os_type=="android"){
        window.android.gotoDetail(JSON.stringify(json_param))
      }
      else if(this.os_type=="ios"){
        window.webkit.messageHandlers.gotoDetail.postMessage(json_param)
      }
      else{
        this.$router.push({
          name: 'gendu2',
          query: {params}
        })
      }
    }
  }
};
</script>

<style scoped>
#knowlevideo {
  min-height: 100vh;
  height:auto;
}

#knowlevideo .video{
  margin-top:0.15rem;
  width:3.75rem;
  height:5rem;
}

#knowlevideo .horizontal {
  flex-direction: row;
  display: flex;
  position: relative;
}

#knowlevideo .bottomarea {
  position: fixed;
  width:100%;
  bottom: 0rem;
  background:white;
}

#knowlevideo .sliderprogress {
  width: 2.4rem;
  margin:auto;
}
#knowlevideo .textprogress {
  font-size: 0.12rem;
  color:#666666;
  margin:auto;
}
#knowlevideo .replaystyle{
  flex-direction: row;
  display: flex;
  position: relative;

  background:rgba(153,153,153,0.08);
  padding:0.08rem 0.15rem;
  margin:0.1rem;
  border-radius: 0.18rem;
  align-items: center;
}
#knowlevideo .nextstyle{
  flex-direction: row;
  display: flex;
  position: relative;

  background:rgba(0,159,248,0.08);
  padding:0.08rem 0.15rem;
  margin:0.1rem 0.1rem 0.1rem 0.45rem;
  border-radius: 0.18rem;
  align-items: center;
}
#knowlevideo .replayicon {
  display: block;
  width: 0.14rem;
  height: 0.14rem;
  margin-right: 0.06rem;
}
#knowlevideo .nexticon {
  display: block;
  width: 0.06rem;
  height: 0.1rem;
  margin-left: 0.06rem;
}
#knowlevideo .playbutton {
  display: block;
  width: 0.55rem;
  height: 0.55rem;
  margin-left:0.35rem;
}

</style>

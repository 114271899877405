
<template>
  <div id="outline">
    <div style="position: relative;padding:0.15rem 0rem;">
      <div class="horizontal" style="align-items: center;" @click="show_select_panel=!show_select_panel">
        <div style="padding-left:1.5rem;font-size:0.13rem;color:#999999;">更多分类</div>
        <img v-if="!show_select_panel" style="display: block;width:0.2rem;height:0.1rem;" src="../assets/down_arrow.png">
        <img v-if="show_select_panel" style="display: block;width:0.2rem;height:0.1rem;" src="../assets/up_arrow.png">
      </div>
      <div style="position: absolute;top: 0.1rem;right: 0.1rem;z-index: 1;">
        <div v-if="show_select_panel" style="background: rgba(255,255,255,0.8);padding:0.1rem;font-size: 0.16rem;border-radius: 0.06rem;">
          <div style="padding:0.08rem; border: 0.01rem solid #72C495;border-radius: 0.05rem;color:#72C495;" @click="loadNewAlbum('S00E')">通途主题课</div>
          <div style="padding:0.08rem; border: 0.01rem solid #72C495;border-radius: 0.05rem;color:#72C495; margin-top:0.08rem;" @click="loadNewAlbum('S00G')">口语练习课</div>
          <div style="padding:0.08rem; border: 0.01rem solid #72C495;border-radius: 0.05rem;color:#72C495; margin-top:0.08rem;" @click="loadNewAlbum('S00I')">听力练习课</div>
          <div style="padding:0.08rem; border: 0.01rem solid #72C495;border-radius: 0.05rem;color:#72C495; margin-top:0.08rem;" @click="loadNewAlbum('S00D')">轻松听专辑</div>
        </div>
      </div>
    </div>
    <!--<div class="levelbrief"><img class="levelbriefpic" v-bind:src="levelbriefpic" alt=""></div>-->
		
    <div v-for="(item,index) in levels" :key="index">
      <img v-if="item.subalbumlist.length>0" class="headicon" v-bind:src="item.headicon" alt="">
      <div class="subalbumlist">
        <div v-for="(item2,index2) in item.subalbumlist" :key="index2">
          <div style="position: relative;">
            <img class="coverimage" v-bind:src="item2.cover_image_url" alt="" @click="gotoSubAlbum(item2)">
            <!--
            <div v-if="item2.lock_st==0 || stage==0" style="position: absolute;bottom: 0.05rem;right: 0.05rem;">
              <img style="display: block; width:0.2rem; height:0.25rem;" src="../assets/greenlock.png">
            </div>
            -->
          </div>
          
          <div v-if="albumID=='S00E'">
            <div class="title">{{ item2.title_en }}</div>
            <div class="title" style="margin-top: 0">{{item2.title}}</div>
          </div>
          <div v-else>
            <div class="title">{{item2.title}}</div>
          </div>
        </div>
      </div>
    </div>
    <div style="padding:0.15rem;"></div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      userID: "",
      albumID:'',
      stage:0,
      os_type:"",
      show_select_panel:false,

      levelbriefpic:require('../assets/levelbrief.png'),
      levels:[
        {
          headicon:require('../assets/outline1.png'),
          subalbumlist:[]
        },{
          headicon:require('../assets/outline2.png'),
          subalbumlist:[]
        },{
          headicon:require('../assets/outline3.png'),
          subalbumlist:[]
        },{
          headicon:require('../assets/outline4.png'),
          subalbumlist:[]
        },{
          headicon:require('../assets/outline5.png'),
          subalbumlist:[]
        }
      ]
    };
  },
  created() {
    document.title = '通途英语'
    this.getRouterData()
  },
  methods: {
    getRouterData() {
      console.log("route",window.location.href)
      let params = JSON.parse(this.$route.query.params)
      this.userID = params.userID
      this.albumID = params.albumID
      if(window.location.href.includes('os_type=')) this.os_type=this.$route.query.os_type
      this.stage = params.stage

      this.loadAlbum()
    },
    loadNewAlbum(newAlbumID){
      this.show_select_panel=false
      if(newAlbumID!=this.albumID){
        this.albumID = newAlbumID
        this.loadAlbum()
      }
    },
    async loadAlbum(){
      let request_data = {
        'type' : 'sub_album_list',
        'data' : {
          'user_id':this.userID,
          'album_id':this.albumID
        }
      }
      await axios.post('https://caixiaowen.com/tongtu',JSON.stringify(request_data))
      .then((result) =>{
        console.log(result.data)
        this.levels[0].subalbumlist=[]
        this.levels[1].subalbumlist=[]
        this.levels[2].subalbumlist=[]
        this.levels[3].subalbumlist=[]
        this.levels[4].subalbumlist=[]
        result.data.sub_album_list.forEach((item) => {
          this.levels[item.level-1].subalbumlist.push(item)
        });
      }).catch((error) =>{
        console.log(error)
      })
    },
    gotoSubAlbum(item) {
      let params = JSON.stringify({
        userID:this.userID,
        albumID:this.albumID,
        subAlbumID:item.album_id,
        stage:this.stage
      });
      
      var pageName = 'storylist'
      if(this.albumID=="S00G" || this.albumID=="S00I"){
        pageName="storylist2"
      }
      else if(this.albumID=="S00D"){
        pageName="contentflow"
      }
      
      let json_param = {
        'url_param':pageName+"?params=" + params + '&os_type=' + this.os_type,
        'title':"故事列表"
      }
      if(this.os_type=="android"){
        window.android.gotoDetail(JSON.stringify(json_param))
      }
      else if(this.os_type=="ios"){
        window.webkit.messageHandlers.gotoDetail.postMessage(json_param)
      }
      else{
        this.$router.push({
          name: pageName,
          query: { params }
        })
      }
    }
  }
};
</script>

<style scoped>
#outline {
  background: #F5F6F7;
  min-height: 100vh;
  height:auto;
}

#outline .horizontal {
  flex-direction: row;
  display: flex;
  position: relative;
}

#outline .levelbrief{
  margin:0rem 0.15rem;
  border-radius:0.1rem;
  background: white;
}

#outline .levelbriefpic{
  display: block;
  width: 3.15rem;
  height: 2.25rem;
  padding:0.15rem;
}

#outline .headicon{
  display: block;
  width:3.45rem;
  height:0.7rem;
  padding: 0.15rem 0.15rem 0rem 0.15rem;
}

#outline .subalbumlist {
  flex-direction: row;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  background: white;
  margin: 0rem 0.15rem;
}

#outline .coverimage{
  display: block;
  width: 1.5rem;
  height: 1.125rem;
  border-radius:0.08rem;
  margin-left:0.15rem;
  margin-top:0.15rem;
}
#outline .title {
  margin-top: 0.12rem;
  margin-bottom:0.05rem;
  margin-left:0.15rem;
  font-size: 0.14rem;
  width:1.5rem;
  color:#222222;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden; 
  text-overflow: ellipsis; 
  display: -webkit-box; 
  -webkit-line-clamp: 1;
  line-clamp: 1; 
  -webkit-box-orient: vertical;
}
</style>

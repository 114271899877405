
<template>
  <div>
    <div style="padding-top:2rem;font-size:0.18rem;text-align: center;">下载App,请在手机应用商店搜索:</div>
    <div style="flex-direction: row;display: flex;position: relative;margin:auto;display:flex;justify-content:center;padding-top: 0.1rem;">
      <img style="display:block;width: 0.3rem;height: 0.3rem;" src="../assets/logo.png">
      <div style="font-size:0.28rem;padding-left: 0.05rem;">通途英语</div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      //
    };
  },
  created() {
    document.title = '通途英语'
  },
  methods: {
  }
};
</script>

<style scoped>
</style>

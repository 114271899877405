
<template>
  <div id="storylist2">
    <div v-if="showAlbumHead" class="horizontal" style="padding:0.15rem;background:rgba(0, 0, 0, 0.3);">
      <div><img class="coverimage" v-bind:src="subAlbumInfo.cover_image_url2" alt=""></div>
      <div style="padding-left:0.15rem;">
        <div style="margin-top:0.05rem;font-size:0.16rem;font-weight: bold;color:white;">{{subAlbumInfo.title}}</div>
        <div class="horizontal" style="margin-top:0.1rem;font-size:0.12rem;color:white;">
          <div>Level{{subAlbumInfo.level}}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</div>
          <div>{{subAlbumInfo.story_num}}集</div>
        </div>
        <div class="brief">{{subAlbumInfo.brief_info}}</div>
      </div>
    </div>
    <div v-if="storylist.length>0 && show_mode==1">
      <img style="display:block;width: 3.75rem;height: 1.1rem;" src="../assets/weixin_banner1.jpg" alt="">
    </div>
    
    <div v-for="(item,index) in storylist" :key="index">
      <div class="horizontal" style="align-items: center;">
        <div class="seri" :style="{'background':'url('+colormap[index%5]+')'}">
          {{index<9?'0':''}}{{index+1}}
          <div v-if="index>=subAlbumInfo.sale_free_num" style="position: absolute;top: 0;left: 0;background: #fbe2c9;border-radius: 0.05rem;">
            <div style="font-size:0.1rem;color:#666666;font-weight: 500;padding:0.01rem;">VIP</div>
          </div>
        </div>
        <div style="width:2.2rem;margin-left:0.1rem;" @click="selectStory(index)">
          <div class="title" style="padding: 0.08rem 0;font-size:0.15rem;font-weight: bold;">{{item.title_ch}}</div>
          <div class="title">{{item.title_en}}</div>
        </div>
        <div v-if="index>=subAlbumInfo.lock_st">
          <img style="padding-left:0.22rem;display: block; width:0.15rem; height:0.15rem;" src="../assets/redlock2.png">
        </div>
        <div v-else-if="item.history.reporter_status==1" @click="checkReporter(index)">
          <img style="padding-left:0.2rem;display: block; width:0.2rem; height:0.2rem;" src="../assets/trainfinish2.png">
        </div>
      </div>
    </div>
    <div v-if="storylist.length>0 && show_mode==1" class="scroll_tips"  @click="downApp">更多内容与优质体验,请下载APP>></div>
    <div v-else class="scroll_tips">{{scroll_tips}}</div>
  
    <div v-if="showAlbumHead" class="bottomarea">
      <div style="align-items: center;" @click="pick">
        <img v-if="pick_status==0" style="display: block;width:0.35rem;height:0.35rem;margin:auto;" src="../assets/jiahao_1.png">
        <div v-if="pick_status==0" style="padding-top:0.08rem;font-size: 0.12rem;color:black;text-align: center;font-weight: 300;">添加课程</div>
        <img v-if="pick_status==1" style="display: block;width:0.35rem;height:0.35rem;margin:auto;" src="../assets/jianhao_1.png">
        <div v-if="pick_status==1" style="padding-top:0.08rem;font-size: 0.12rem;color:black;text-align: center;font-weight: 300;">移除课程</div>
      </div>
      <div v-if="subAlbumInfo.lock_st<=1" class="learnbutton" @click="start">开始学习</div>
      <div v-else class="learnbutton" @click="start">继续学习</div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import axios from 'axios'

export default {
  data() {
    return {
      userID: '',
      albumID: '',
      subAlbumID:'',
      subAlbumInfo:{},
      stage:0,

      show_mode:0,
      showAlbumHead: false,
      pick_status:false,

      os_type:"",

      storylist: [],
      train_story_no:-1,
      sync_lock:false,

      colormap:[
        require('../assets/color1.png'),
        require('../assets/color2.png'),
        require('../assets/color3.png'),
        require('../assets/color4.png'),
        require('../assets/color5.png')
      ],

      scroll_tips:"",
      loading: false
    };
  },
  created() {
    document.title = '通途英语'
    console.log('created storylist 开始监听scroll')
    window.addEventListener('scroll', this.scroll);
    this.getRouterData()
  },
  mounted(){
    const that = this
    window.refresh = that.refresh
    window.pay = that.pay
    window.doLogin = that.doLogin
  },
  destroyed() {
    console.log("destroyed storylist 销毁监听scroll")
    window.removeEventListener("scroll",this.scroll)
  },
  methods: {
    getRouterData() {
      console.log("route",window.location.href)
      let params = JSON.parse(this.$route.query.params)
      this.userID = params.userID
      this.albumID = params.albumID
      this.subAlbumID = params.subAlbumID
      this.stage = params.stage
      if(window.location.href.includes('os_type=')) this.os_type=this.$route.query.os_type
      if(window.location.href.includes('show_mode=')) this.show_mode=this.$route.query.show_mode

      this.loadStory(true,100)
    },
    scroll() {
      if(this.loading) return
      this.scroll_tips=""
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight-1) {
        console.log('到底啦')
        this.scroll_tips="正在努力加载..."
        this.loadStory(true,10)
      }
    },

    refresh(){
      if(!this.loading){
        console.log('refresh')
        this.storylist.length=0
        this.loadStory(true,100)
      }
    },

    async loadStory(isBottom,storynum) {
      if(this.loading) return
      this.loading=true

      var first=true
      if(this.storylist.length>0) first=false

      let request_data = {
        'type' : 'story_recommend',
        'data' : {
          'user_id':this.userID,
          'record_list': [],
          'need_story_num': storynum,
          'content_scope': 2,
          'album_id':this.albumID,
          'sub_album_id' :this.subAlbumID,
          'first_time' : first
        }
      }
      await axios.post('https://caixiaowen.com/tongtu',JSON.stringify(request_data))
      .then((result) =>{
        console.log(result.data)
        let tmplist = result.data['story_list'];
        if(tmplist.length==0) this.scroll_tips="没有数据了."
        tmplist.forEach((item) => {
          if(isBottom){
            this.storylist.push(item)
          }
          else{
            this.storylist.unshift(item)
          }
        });
        this.subAlbumInfo=result.data['sub_album_data']
        //if(this.stage<=1) this.subAlbumInfo.lock_st-=1
        if(this.show_mode==0){
          this.showAlbumHead=true
          this.pick_status=result.data['pick_status']
        }
        else {
          this.storylist.length=3
        }
        this.loading=false
      }).catch((error) =>{
        console.log(error)
        this.loading=false
      })
    },

    start(){
      if(this.sync_lock) return
      this.train_story_no=-1
      this.selectStory(this.subAlbumInfo.lock_st-1)
    },
    pick(){
      if(this.userID.length==0){
        if(this.os_type=="android"){
          window.android.doLogin("")
        }
        else if (this.os_type=="ios"){
          window.webkit.messageHandlers.doLogin.postMessage(null)
        }
        else{
          Toast("你还没有登录哦")
        }
        return
      }

      this.pick_status=!this.pick_status
      let request_data = {
        'type' : 'user_sub_album_list',
        'data' : {
          'user_id':this.userID,
          'album_id':this.albumID,
          'sub_album_id':this.subAlbumID,
          'pick_status':this.pick_status
        }
      }
      axios.post('https://caixiaowen.com/tongtu',JSON.stringify(request_data))
      if(this.pick_status){
        Toast("课程已添加")
      }else {
        Toast("课程已移除")
      }
    },

    selectStory(index){
      if(this.sync_lock) return

      if(this.userID.length==0){
        if(this.os_type=="android"){
          window.android.doLogin("")
        }
        else if (this.os_type=="ios"){
          window.webkit.messageHandlers.doLogin.postMessage(null)
        }
        else{
          Toast("你还没有登录哦")
        }
      }
      else if(this.stage<=1 && index>=this.subAlbumInfo.sale_free_num){
        let json_param = {
          'url_param':"marketshow?type=0&userID=" + this.userID + '&os_type=' + this.os_type,
          'title':""
        }
        //
        if(this.os_type=="android"){
          window.android.gotoDetail(JSON.stringify(json_param))
        }
        else if (this.os_type=="ios"){
          window.webkit.messageHandlers.gotoDetail.postMessage(json_param)
        }
        else{
          Toast("请联系客服报名哦!")
        }
      }
      else if(index>=this.subAlbumInfo.lock_st){
        Toast("未解锁,请按顺序学习哦!")
      }
      else{
        this.train_story_no=index
        
        if(!this.pick_status){
          this.sync_lock=true
          this.pick()
          var that = this
          setTimeout(function () {
            that.sync_lock=false
            that.trainStep()
          }, 1500)
        } else {
          this.trainStep()
        }
      }
    },

    trainStep(){
      if(this.train_story_no>=this.storylist.length){
        Toast("已学完全部课程")
        return
      }

      var params = JSON.stringify({
        userID:this.userID,
        albumID:this.albumID,
        storyID:this.storylist[this.train_story_no].story_id,
        crossInfo:{
          level:this.subAlbumInfo.level,
          sub_album_id:this.subAlbumInfo.album_id,
          train_story_no:this.train_story_no,
          //video_store_status:this.storylist[this.train_story_no].history.user_store,
          //video_url:this.storylist[this.train_story_no].video
        }
      })

      var pagename="gendu2"
      var titlename="跟读练习"
      if(this.albumID=='S00I'){
        pagename="expresswords"
        titlename="听力练习"
      }
      let json_param = {
        'url_param':pagename+"?params=" + params + '&os_type=' + this.os_type,
        'title':titlename
      }

      if(this.os_type=="android"){
        window.android.gotoDetail(JSON.stringify(json_param))
      }
      else if (this.os_type=="ios"){
        window.webkit.messageHandlers.gotoDetail.postMessage(json_param)
      }
      else{
        this.$router.push({
          name: pagename,
          query: {params}
        })
      }
    },
    checkReporter(index) {
      if(this.albumID=="S00I"){
        Toast("听力课没有学习报告哟")
        return
      }
      
      let params = JSON.stringify({
        userID: this.userID,
        albumID: this.albumID,
        storyID: this.storylist[index].story_id,
        update: false,
        from_type : 3
      })
      let json_param = {
        'url_param': "reportcard?params=" + params + '&os_type=' + this.os_type,
        'title': ""
      }

      if (this.os_type == "android") {
        window.android.gotoDetail(JSON.stringify(json_param))
      }
      else if (this.os_type == "ios") {
        window.webkit.messageHandlers.gotoDetail.postMessage(json_param)
      }
      else {
        this.$router.push({
          name: 'reportcard',
          query: { params }
        })
      }
    },
    downApp(){
      this.$router.push({
        name: 'appdown',
        query: {}
      })
    }
  }
};
</script>

<style scoped>
#storylist2 {
  min-height: 100vh;
  height:auto;
}
#storylist2 .horizontal {
  flex-direction: row;
  display: flex;
  position: relative;
}
#storylist2 .coverimage{
  display: block;
  width: 1rem;
  height: 1rem;
  border-radius:0.1rem;
}
#storylist2 .brief {
  margin-top: 0.18rem;
  font-size: 0.12rem;
  width:2.2rem;
  color:rgba(255, 255, 255, 0.5);
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden; 
  text-overflow: ellipsis; 
  display: -webkit-box; 
  -webkit-line-clamp: 2; 
  line-clamp: 2; 
  -webkit-box-orient: vertical;
  line-height: 0.18rem;
}

#storylist2 .title{
  font-size: 0.12rem;
  color:#444444;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

#storylist2 .seri{
  border-radius: 0.3rem;
  font-size: 0.15rem;
  text-align: center;
  color: white;
  padding:0.15rem;
  margin:0.2rem 0.1rem 0.1rem 0.2rem;
  position: relative;
}

#storylist2 .scroll_tips {
  padding-top: 0.1rem;
  padding-bottom: 0.8rem;
  text-align: center;
  font-size: 0.14rem;
  color:rgb(0, 0, 0, 0.4);
  background: white;
}

#storylist2 .bottomarea {
  flex-direction: row;
  display: flex;
  position: fixed;
  width:100%;
  bottom:0;
  background: rgba(250,250,250,0.95);
  z-index: 1;
  padding:0.15rem 0.25rem;
}

#storylist2 .learnbutton {
  background:rgb(80,199,145);
  border-radius: 0.3rem;
  width:2.5rem;
  margin-left:0.2rem;
  padding-top:0.15rem;
  text-align: center;
  font-size: 0.2rem;
  color:white;
}
</style>


<template>
  <div id="storevideo">
		<div class='storylist'>
      <div class='story' v-for="(item,index) in storylist" :key="index">
        <div><img class="coverimage" v-bind:src="item.cover_image_url" alt="" @click="playVideo(index)"></div>
        <div class="brief">{{item.title_en}}</div>
        <div class='anchor'>
          <div><img class="anchorimage" src="../assets/videoauthor.png" alt=""></div>
          <div class="anchorname">贾毅</div>
        </div>
      </div>
    </div>
    <div class="scroll_tips">{{scroll_tips}}</div>

    <div class="mask" v-if="showMask">
      <div>
        <img style="display:block;width:0.3rem;height:0.3rem;margin-left:3.4rem;margin-bottom: 0.1rem;" src="../assets/close_x2.png" @click='closeVideo()'/>
        <div class="video">
          <video-player class="video-player vjs-custom-skin"
            ref="videoPlayer" 
            :playsinline="true" 
            :options="playerOptions">
          </video-player>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
//import { Toast } from 'vant'
import axios from 'axios'

export default {
  data() {
    return {
      userID: "",
      albumID: "",
      storylist: [],

      showMask:false,
      videoIdx:-1,

      scroll_tips:"",
      loading: false,

      playerOptions : {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: true, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '3:4', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [],
        poster: require("../assets/cover_video.jpg"), //你的封面地址
        // width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        flash: { hls: { withCredentials: false } },
        html5: { hls: { withCredentials: false } },
        controls: true,
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true  //全屏按钮
        }
      },
    };
  },
  created() {
    //Toast("h5 created")
    document.title = '通途英语'
    console.log('created storevideo 开始监听scroll')
    window.addEventListener('scroll', this.scroll);
    this.getRouterData()
  },
  destroyed() {
    console.log("destroyed storevideo 销毁监听scroll")
    window.removeEventListener("scroll",this.scroll)
  },
  methods: {
    getRouterData() {
      console.log("route",window.location.href)
      this.userID = this.$route.query.userID
      if(window.location.href.includes('albumID=')) {
        this.albumID = this.$route.query.albumID
      }
      this.loadStory(true,20)
    },
    scroll() {
      if(this.loading) return
      this.scroll_tips=""
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight-1) {
        console.log('到底啦')
        this.scroll_tips="正在努力加载..."
        this.loadStory(true,10)
      }
    },
    
    async loadStory(isBottom,storynum) {
      if(this.loading) return
      this.loading=true

      var first=true
      if(this.storylist.length>0) first=false

      var contentscope=1
      if(this.albumID!='') contentscope=2
      let request_data = {
        'type' : 'story_recommend',
        'data' : {
          'user_id':this.userID,
          'record_list': [],
          'need_story_num': storynum,
          'content_scope': contentscope,
          'album_id':'S00E',
          'first_time' : first
        }
      }
      await axios.post('https://caixiaowen.com/tongtu',JSON.stringify(request_data))
      .then((result) =>{
        console.log(result.data)
        let tmplist = result.data['story_list'];
        if(tmplist.length==0) this.scroll_tips="没有数据了."
        tmplist.forEach((item) => {
          if(isBottom){
            this.storylist.push(item)
          }
          else{
            this.storylist.unshift(item)
          }
        });
        this.loading=false
      }).catch((error) =>{
        console.log(error)
        this.loading=false
      })
    },
    playVideo(index) {
      this.videoIdx=index
      this.showMask = true

      this.playerOptions.sources=[{
        type: "video/mp4",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
        src: this.storylist[index].video //url地址
      }]
    },
    closeVideo(){
      this.showMask=false
    }
  }
};
</script>

<style scoped>
#storevideo {
  background:rgb(246, 247, 248);
  min-height: 100vh;
  height:auto;
}
#storevideo .storylist {
  flex-direction: row;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  padding-top:0.12rem;
}
#storevideo .story {
  margin:0.18rem 0rem 0rem 0.15rem;
  width: 1.65rem;
  border-radius:0.08rem;
  background:white;
}
#storevideo .coverimage{
  display: block;
  width: 1.65rem;
  height: 1.65rem;
  border-radius:0.08rem;
}
#storevideo .brief {
  margin-top: 0.1rem;
  font-size: 0.14rem;
  width:1.6rem;
  color:black;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden; 
  text-overflow: ellipsis; 
  display: -webkit-box; 
  -webkit-line-clamp: 2; 
  line-clamp: 2; 
  -webkit-box-orient: vertical;
  line-height: 0.23rem;
  padding-left:0.05rem;
}
#storevideo .anchor {
  margin-top:0.1rem;
  align-items: center;
  flex-direction: row;
  display: flex;
  position: relative;
  padding-left:0.05rem;
  padding-bottom:0.05rem;
}
#storevideo .anchorimage{
  display: block;
  width: 0.25rem;
  height: 0.25rem;
  border-radius:0.125rem;
}

#storevideo .anchorname {
  font-size: 0.1rem;
  padding-left: 0.1rem;
  width:1.2rem;
  color:rgb(180, 180, 180);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#storevideo .scroll_tips {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  text-align: center;
  font-size: 0.14rem;
  color:rgb(16, 16, 16);
}

#storevideo .mask{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.7);
  z-index:9999;

  width: 100%;
  height: 100%vh;

  display: flex;
  align-items: center;
  justify-content: center;
}
#storevideo .video{
  width:3.75rem;
  height:5rem;
}
</style>


<template>
  <div class="" id="reportcard">
    <div v-if="!loaded" style="padding: 0.1rem;text-align: center; font-size: 0.14rem; color:rgb(0, 0, 0, 0.2);">数据加载中...</div>
    <div v-else style="background: #D2F4FF;">
      <div class="horizontal" style="padding:0.2rem;">
        <div>
          <div class="horizontal">
            <img style="display: block;width:0.5rem;height:0.5rem;border-radius: 0.25rem;" src='../assets/headtmp.png' alt="">
            <div style="padding-left:0.15rem;">
              <div style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;width:1.2rem; font-size:0.18rem;color:rgb(28,80,109);;padding-top:0.15rem;">{{reportJson.user_name}}</div>
              <!--<div style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;width:1.2rem;font-size:0.15rem;color:rgba(28,80,109,0.5);padding-top:0.08rem;">账号:{{userID}}</div>-->
            </div>
          </div>
          <div style="font-size:0.32rem;color:black;padding-top:0.15rem;">学习报告</div>
          <div class="horizontal" style="font-size:0.16rem;color:#1C506D;padding-top:0.1rem;">
            <div>《</div>
            <div style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;max-width:1.7rem;">{{reportJson.story_data.title_en}}</div>
            <div>》</div>
          </div>
        </div>
        <img style="display: block;width:1.1rem;height:1.3rem;padding-left:0.35rem;" src='../assets/rewardcup.png' alt="">
      </div>
      <div style="margin-top:0.3rem;"></div>
      <!--总体-->
      <div style="position: relative; margin:0.15rem;width:3.45rem;background:white;border-radius: 0.2rem;">
        <div style="position: absolute;top: -0.22rem;left: 0.65rem;">
          <img v-if="good_num==doing_num" style="display: block; width:2.15rem; height:0.67rem;" src="../assets/achievementA.png">
          <img v-else-if="good_num/doing_num>=0.8" style="display: block; width:2.15rem; height:0.67rem;" src="../assets/achievementB.png">
          <img v-else-if="good_num/doing_num>=0.65" style="display: block; width:2.15rem; height:0.67rem;" src="../assets/achievementC.png">
          <img v-else style="display: block; width:2.15rem; height:0.67rem;" src="../assets/achievementD.png">
        </div>
        <div style="padding-top:0.45rem;font-size:0.2rem;color:#222222;text-align:center;font-weight: bold;">整体表现</div>
        <div style="padding-top:0.1rem;font-size:0.14rem;color:#999999;text-align:center;">请继续加油哦</div>
        <div class="horizontal" style="align-items: center;justify-content: center;padding:0.15rem;">
          <div style="margin:auto">
            <div style="padding-top:0.15rem;font-size:0.22rem;color:#222222;text-align:center;font-weight: bold;">{{doing_num}}</div>
            <div style="padding-top:0.1rem;font-size:0.12rem;color:#666666;text-align:center;">回答总数</div>
          </div>
          <div style="margin:auto">
            <div style="padding-top:0.15rem;font-size:0.22rem;color:#222222;text-align:center;font-weight: bold;">{{good_num}}</div>
            <div style="padding-top:0.1rem;font-size:0.12rem;color:#666666;text-align:center;">表现不错</div>
          </div>
          <div style="margin:auto">
            <div style="padding-top:0.15rem;font-size:0.22rem;color:#222222;text-align:center;font-weight: bold;">{{doing_num-good_num}}</div>
            <div style="padding-top:0.1rem;font-size:0.12rem;color:#666666;text-align:center;">有待提高</div>
          </div>
        </div>
      </div>
      <!--分项分析-->
      <div v-if="from_type<=1" style="margin:0.15rem;width:3.45rem;background:white;border-radius: 0.2rem;">
        <div class="horizontal" style="align-items:center;justify-content: center;padding-top:0.15rem;">
          <div><img src="../assets/Aplus.png" style="display:block;width:0.3rem;height:0.3rem;"></div>
          <div style="padding-left:0.1rem;font-size:0.16rem;color:#222222;font-weight: bold;">分项分析</div>
        </div>

        <div class="horizontal" style="padding:0.2rem;align-items:center;">
          <div style="width:0.04rem;height:0.20rem;background:#009FF8"></div>
          <div style="padding-left:0.1rem;font-size:0.16rem;color:#222222;font-weight: bold;">盲听理解</div>
          <div style="padding-left:0.1rem;font-size:0.14rem;color:#999999;">
            共{{reportJson.compre_data.question_num}}道题，答对{{reportJson.compre_data.good_num}}道题
          </div>
        </div>
        <div style="padding-left:0.2rem;font-size:0.15rem;color:#666666;">正确选项:</div>
        <div v-for="(item,index) in reportJson.compre_data.correct_answer" :key="index">
          <div class="option" style="background: rgba(0,159,248,0.08);padding-left:0rem;">
            <div style="color:white;background:#009FF8;border-radius: 0.05rem; padding:0.15rem 0.08rem;">{{index+1}}</div>
            <div style="padding-left: 0.1rem;">{{item.alpha_no}}:{{item.option_txt}}</div>
          </div>
        </div>

        <div class="horizontal" style="padding:0.2rem;align-items:center;">
          <div style="width:0.04rem;height:0.20rem;background:#009FF8"></div>
          <div style="padding-left:0.1rem;font-size:0.16rem;color:#222222;font-weight: bold;">单词闯关</div>
          <div style="padding-left:0.1rem;font-size:0.14rem;color:#999999;">
            共{{reportJson.geci_data.question_num}}道题，答对{{reportJson.geci_data.good_num}}道题
          </div>
        </div>
        <div v-if="reportJson.geci_data.good_num<reportJson.geci_data.question_num">
          <div style="padding-left:0.2rem;font-size:0.15rem;color:#666666;">需重点关注:</div>
          <div class="horizontal" style="flex-wrap:wrap;">
            <div class="option" style="margin-right:0rem;" v-for="(item,index) in reportJson.geci_data.bad_words" :key="index">
              <div style="width:1.18rem;text-align:center;">{{item}}</div>
            </div>
          </div>
          <div class="option" v-for="(item,index) in reportJson.geci_data.bad_sentences" :key="index">{{item}}</div>
        </div>

        <div class="horizontal" style="padding:0.2rem;align-items:center;">
          <div style="width:0.04rem;height:0.20rem;background:#009FF8"></div>
          <div style="padding-left:0.1rem;font-size:0.16rem;color:#222222;font-weight: bold;">口语测评</div>
          <div style="padding-left:0.1rem;font-size:0.14rem;color:#999999;">
            共{{reportJson.gendu_data.question_num}}道题，80分以上{{reportJson.gendu_data.good_num}}道
          </div>
        </div>
        
      </div>
      <!--口语分析-->
      <div style="margin:0.15rem;width:3.45rem;background:white;border-radius: 0.2rem;">
        <div class="horizontal" style="align-items:center;justify-content: center;padding-top:0.15rem;">
          <div><img src="../assets/iconspeak.png" style="display:block;width:0.3rem;height:0.3rem;"></div>
          <div style="padding-left:0.1rem;font-size:0.16rem;color:#222222;font-weight: bold;">口语分析</div>
        </div>
        <div class="horizontal" style="align-items: center;justify-content: center;padding:0.2rem 0.5rem 0rem 0.5rem;">
          <div style="margin:auto">
            <div class="horizontal" style="height:0.32rem;">
              <div style="font-size:0.32rem;color:#009FF8;text-align:center;">{{reportJson.gendu_data.total_score}}</div> 
              <div style="padding-top:0.15rem;font-size:0.14rem;color:#222222;">分</div>  
            </div>
            <div style="padding-top:0.15rem;font-size:0.15rem;color:#666666;text-align:center;">总得分</div>
          </div>
          <div style="margin:auto" @click="play">
            <img style="margin:auto;display:block;width:0.25rem;height:0.25rem;" v-bind:src="playicon" alt="">
            <div style="padding-top:0.15rem;font-size:0.15rem;color:#666666;text-align:center;">我的录音</div>
          </div>
        </div>

        <div class="horizontal" style="align-items: center;justify-content: center;padding:0.2rem;">
          <div style="margin:auto;background: rgba(0,159,248,0.08);width:0.75rem;height:0.4rem;padding:0.1rem;border-radius: 0.1rem;">
            <div style="font-size:0.14rem;color:#00BCF8;text-align:center;">{{reportJson.gendu_data.pron_score}}分</div>
            <div style="padding-top:0.1rem;font-size:0.13rem;color:#666666;text-align:center;">发音准确度</div>
          </div>
          <div style="margin:auto;background: rgba(0,159,248,0.08);width:0.75rem;height:0.4rem;padding:0.1rem;border-radius: 0.1rem;">
            <div style="font-size:0.14rem;color:#00BCF8;text-align:center;">{{reportJson.gendu_data.cont_score}}分</div>
            <div style="padding-top:0.1rem;font-size:0.13rem;color:#666666;text-align:center;">发音连贯度</div>
          </div>
          <div style="margin:auto;background: rgba(0,159,248,0.08);width:0.75rem;height:0.4rem;padding:0.1rem;border-radius: 0.1rem;">
            <div style="font-size:0.14rem;color:#00BCF8;text-align:center;">{{reportJson.gendu_data.integrity_score}}分</div>
            <div style="padding-top:0.1rem;font-size:0.13rem;color:#666666;text-align:center;">发音完整度</div>
          </div>
        </div>
        
        <div v-if="reportJson.gendu_data.bad_words.length>0">
          <div style="padding-left:0.2rem;font-size:0.15rem;color:#666666;">发音可提升单词:</div>
          <div class="horizontal" style="flex-wrap:wrap;">
            <div class="option" style="margin-right:0rem;" v-for="(item,index) in reportJson.gendu_data.bad_words" :key="index">
              <div style="width:1.18rem;text-align:center;">{{item.toLowerCase()}}</div>
            </div>
          </div>
        </div>
        <div style="margin:0.2rem;font-size:0.15rem;">
          <div style="color:#666666;">评价:</div>
          <div style="color:#222222;padding-top:0.1rem;padding-bottom: 0.2rem;">{{reportJson.gendu_data.comment}}</div>
        </div>
        
      </div>
      <div style="padding-bottom: 0.6rem;"></div>

      <div v-if="from_type==0 || from_type==3" class="bottomarea">
        <div class="horizontal" style="padding:0.15rem 0rem;align-items: center;">
          <div class="horizontal" style="margin-left:0.1rem;align-items: center;" @click="gotoStoryList()">
            <div><img class="replayicon" src="../assets/goback.png" alt=""></div>
            <div style="color:#222222;font-size:0.14rem;">返回列表</div>
          </div>
          <!--
          <div class="horizontal" style="margin-left:0.35rem;;align-items: center;background: #009FF8;border-radius: 0.2rem;padding:0.08rem 0.2rem;" @click="share()">
            <div><img class="replayicon" src="../assets/sharereporter.png" alt=""></div>
            <div style="color:white;font-size:0.14rem;">分享卡片</div>
          </div>
          -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { Toast } from 'vant'
import {Howl} from 'howler'

export default {
  data() {
    return {
      userID: "",
      storyID:'',
      loaded:false,

      os_type:"",

      //0 主题课且需要返回列表
      //1 主题课但不需要返回列表
      //2 口语课但不需要返回列表
      //3 口语课且需要返回列表
      from_type:0,

      reportJson:{},
      question_num:0,
      doing_num:0,
      good_num:0,
      
      sound: null,
      playicon: require('../assets/relisten2.png')
    };
  },
  created() {
    document.title = '通途英语'
    this.getRouterData()
  },
  mounted(){
    const that = this
    window.finish = that.finish
    window.refresh = that.refresh
  },
  beforeDestroy() {
    this.sound && this.sound.stop()
  },
  methods: {
    async getRouterData() {
      console.log("route",window.location.href)
      if(window.location.href.includes('os_type=')) this.os_type=this.$route.query.os_type

      var update=false
      var album_id=""
      var sub_album_id=""
      var story_no=0
      if(window.location.href.includes('params=')){
        let params = JSON.parse(this.$route.query.params)
        this.userID = params.userID
        this.storyID = params.storyID
        if(Object.prototype.hasOwnProperty.call(params,'from_type')) this.from_type = params.from_type

        if(Object.prototype.hasOwnProperty.call(params,'update')) update=params.update
        if(update){
          album_id=params.albumID
          sub_album_id=params.crossInfo.sub_album_id
          story_no=params.crossInfo.train_story_no
        }
      } else {
        this.userID = this.$route.query.userID
        this.storyID = this.$route.query.storyID
        this.from_type = this.$route.query.from_type

        if(Object.prototype.hasOwnProperty.call(this.$route.query,'update')) update=this.$route.query.update
        if(update){
          album_id=this.$route.query.album_id
          sub_album_id=this.$route.query.sub_album_id
          story_no=this.$route.query.story_no
        }
      }

      var request_data=''
      if(update){
        request_data = {
          'type' : 'report_data',
          'data' : {
            'user_id':this.userID,
            'story_id':this.storyID,
            'update':update,

            'album_id':album_id,
            'sub_album_id':sub_album_id,
            'story_no':story_no
          }
        }
      }
      else{
        request_data = {
          'type' : 'report_data',
          'data' : {
            'user_id':this.userID,
            'story_id':this.storyID,
            'update':update
          }
        }
      }

      await axios.post('https://caixiaowen.com/tongtu',JSON.stringify(request_data))
      .then((result) =>{
        console.log(result.data)
        this.reportJson=result.data.report_data
        
        this.question_num+=this.reportJson.gendu_data.question_num
        if(this.from_type<=1){
          this.question_num+=this.reportJson.compre_data.question_num
          this.question_num+=this.reportJson.geci_data.question_num
        }

        this.doing_num+=this.reportJson.gendu_data.doing_num
        if(this.from_type<=1){
          this.doing_num+=this.reportJson.compre_data.doing_num
          this.doing_num+=this.reportJson.geci_data.doing_num
        }

        this.good_num+=this.reportJson.gendu_data.good_num
        if(this.from_type<=1){
          this.good_num+=this.reportJson.compre_data.good_num
          this.good_num+=this.reportJson.geci_data.good_num
        }
        
        this.loaded=true
      }).catch((error) =>{
        console.log(error)
      })
    },

    refresh(){
      //
    },
    finish(){
      this.sound && this.sound.stop()
      this.sound=null
    },

    play() {
      if(this.sound==null) {
        var wav=this.reportJson.gendu_data.mp3url
      
        console.log("initial sound",wav)
        this.sound = new Howl({
          src: [wav],

          onplay:() => {
            console.log("开始播放啦")
            this.playicon=require('../assets/gendustop.png')
          },
          onstop:() => {
            console.log("停止播放啦")
            this.playicon=require('../assets/relisten2.png')
          },
          onpause:() => {
            console.log("停止播放啦")
            this.playicon=require('../assets/relisten2.png')
          },
          onend:() => {
            console.log("结束播放啦")
            this.playicon=require('../assets/relisten2.png')
          },
          onloaderror:() => {
            Toast("声音导入失败")
          },
          onplayerror:() => {
            Toast("声音播放失败")
          },
        })
      }
      this.sound[this.sound.playing()? 'pause' : 'play']()
    },

    gotoStoryList(){
      if(this.os_type=="android"){
        window.android.backToMain("")
      }
      else if (this.os_type=="ios"){
        window.webkit.messageHandlers.backToMain.postMessage(null)
      }
      else{
        Toast("正在开发中")
      }
      
    },
    share(){
      Toast("正在开发中")
    },
  }
};
</script>

<style scoped>
#reportcard {
  min-height: 100vh;
  height:auto;
}

#reportcard .horizontal {
  flex-direction: row;
  display: flex;
  position: relative;
}

#reportcard .option {
  flex-direction: row;
  display: flex;
  position: relative;

  background:rgba(153,153,153,0.08);
  padding:0.12rem;
  margin:0.1rem 0.2rem 0rem 0.2rem;
  border-radius: 0.1rem;
  align-items: center;

  font-size:0.15rem;
  color:#222222;
  line-height: 0.2rem;
}

#reportcard .bottomarea {
  position: fixed;
  width:100%;
  bottom: 0rem;
  background:white;
}

#reportcard .replayicon {
  display: block;
  width: 0.2rem;
  height: 0.2rem;
  margin-right: 0.05rem;
}

</style>

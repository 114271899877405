<template>
  <div class="" id="homepage">
    <div v-if="loading" style="padding: 0.1rem;text-align: center; font-size: 0.14rem; color:rgb(0, 0, 0, 0.2);">数据加载中...</div>
    <van-swipe :autoplay="3000">
      <van-swipe-item v-for="(item,index) in bannerlist" :key="index">
        <div><img class="bannerimage" v-bind:src="item.image_url" alt="" @click="gotoBanner(item)"></div>
      </van-swipe-item>
    </van-swipe>
		<div class='album' v-for="(item,index) in albumlist" :key="index" @click="gotoAlbum(item)">
      <div class='horizontal'>
        <img class="coverimage" v-bind:src="item.cover_image_url" alt="">
        <div style="padding-left: 0.12rem;">
          <div class="title">{{item.title}}</div>
          <div class="subtitle">{{item.subtitle}}</div>
          <div style="margin-top: 0.12rem;font-size: 0.15rem;font-weight: bold;">
            <div v-if="item.stage==0" style="color:grey">点击报名</div>
            <div v-if="item.stage==1" style="color:#4FD37F">续费开通</div>
            <div v-if="item.stage==2" style="color:#4FD37F">学习中</div>
            <div v-if="item.stage==3" style="color:#4FD37F">点击体验</div>
          </div>
        </div>
      </div>
      <div v-if="item.stage!=3" style="background:#FFFBF1;border-radius: 0.08rem;margin-top: 0.1rem;">
        <div style="font-size:0.15rem;color:#C49C58;font-weight: bold;padding:0.12rem;">会员专属权益</div>
        <div class='horizontal' style="padding-bottom:0.15rem; padding-left:0.12rem;">
          <img src="../assets/vipicon1.png" style="display:block;width:0.12rem;height:0.12rem;">
          <div style="padding-left:0.05rem;padding-right:0.2rem;font-size:0.12rem;color:#999999">国际标准进阶课程</div>
          <img src="../assets/vipicon2.png" style="display:block;width:0.12rem;height:0.12rem;">
          <div style="padding-left:0.05rem;padding-right:0.2rem;font-size:0.12rem;color:#999999">智能听说训练系统</div>
          <!--<img src="../assets/vipicon3.png" style="display:block;width:0.12rem;height:0.12rem;">
          <div style="padding-left:0.05rem;font-size:0.12rem;color:#999999">智能发音测评</div>-->
        </div>
      </div>
    </div>
	</div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      os_type:"",
      loading:true,

      albumlist : [],//启动时，从服务器获取
      bannerlist : [],
      userID:""
    }
  },

  methods : {
    gotoAlbum(item) {
      if(item.stage==3){//体验课
        let params = JSON.stringify({
          userID:this.userID,
          albumID:'S00F',
          subAlbumID:'S00F_1_1',
          stage:item.stage
        });
        let json_param = {
          'url_param':"storylist?params=" + params + '&os_type=' + this.os_type,
          'title':"故事列表"
        }
        if(this.os_type=="android"){
          window.android.gotoDetail(JSON.stringify(json_param))
        }
        else if(this.os_type=="ios"){
          window.webkit.messageHandlers.gotoDetail.postMessage(json_param)
        }
        else{
          this.$router.push({
            name: 'storylist',
            query: { params }
          })
        }
      }
      else{
        var params = {
          userID:this.userID,
          albumID:item.album_id,
          stage:item.stage
        }
        params = JSON.stringify(params)

        let json_param = {
          'url_param':"outline?params=" + params + '&os_type=' + this.os_type,
          'title':"课程列表"
        }

        if(this.os_type=="android"){
          window.android.gotoDetail(JSON.stringify(json_param))
        }
        else if (this.os_type=="ios"){
          window.webkit.messageHandlers.gotoDetail.postMessage(json_param)
        }
        else{
          this.$router.push({
            name: 'outline',
            query: {params}
          })
        }
      }
    },
    gotoBanner(item){
      if(item.type==1){
        let json_param = {
          'url_param':"marketshow?type=0&userID="+this.userID+"&os_type="+this.os_type,
          'title':""
        }
        if(this.os_type=="android"){
          window.android.gotoDetail(JSON.stringify(json_param))
        }
        else if(this.os_type=="ios"){
          window.webkit.messageHandlers.gotoDetail.postMessage(json_param)
        }
      }
      else if(item.type==2){
        let json_param = {
          'url_param':"contentflow?userID="+this.userID+"&os_type="+this.os_type,
          'title':"听听故事"
        }
        if(this.os_type=="android"){
          window.android.gotoDetail(JSON.stringify(json_param))
        }
        else if(this.os_type=="ios"){
          window.webkit.messageHandlers.gotoDetail.postMessage(json_param)
        }
      }
    },
    refresh(){
      console.log('refresh')
      if(this.albumlist.length>=2 && this.albumlist[1].stage!=2){
        this.load()
      }
    },
    async load(){
      let request_data = {
        'type' : 'album_list',
        'data' : {
          "user_id":this.userID
        }
      }
      
      const result = await axios.post('https://caixiaowen.com/tongtu',JSON.stringify(request_data))
      console.log(result.data)
      this.albumlist = result.data.album_list
      this.bannerlist = result.data.info.banner_list
      this.loading = false
    }
  },

  async created() {
    document.title = '通途英语'
    console.log("route",window.location.href)
    this.userID = this.$route.query.userID
    if(window.location.href.includes('os_type=')) this.os_type=this.$route.query.os_type

    this.load()
  },
  mounted(){
    const that = this
    window.refresh = that.refresh
  }
}
</script>

<style scoped>

#homepage {
  min-height: 100vh;
  height:auto;
}

#homepage .bannerimage{
  margin:0.12rem;
  display: block;
  width: 3.5rem;
  height: 1.5rem;
  border-radius:0.08rem;
}

#homepage .album {
  margin: 0.15rem;
  padding: 0.12rem;
  border: 0.01rem solid #eee;
  border-radius:0.15rem;
  box-shadow: 0.01rem 0.01rem 0.01rem #d8d7d7;
}
#homepage .horizontal {
  flex-direction: row;
  display: flex;
  position: relative;
}

#homepage .coverimage{
  display: block;
  width: 0.9rem;
  height: 1.12rem;
  border: 0.01rem solid #eee;
  border-radius:0.1rem;
}

#homepage .title {
  margin-top: 0.1rem;
  font-size: 0.18rem;
  color:#222222;
  font-weight: bolder;
}
#homepage .subtitle {
  margin-top: 0.08rem;
  font-size: 0.12rem;
  color:#999999;

  text-overflow: -o-ellipsis-lastline;
  overflow: hidden; 
  text-overflow: ellipsis; 
  display: -webkit-box; 
  -webkit-line-clamp: 2; 
  line-clamp: 2; 
  -webkit-box-orient: vertical;
  line-height: 0.16rem;
}
#homepage .oriprice {
  padding-left: 0.02rem;
  padding-top: 0.05rem;
  text-decoration:line-through;
  color:grey;
}
</style>

import Vue from 'vue'
import Router from 'vue-router'

import AlbumOutline from '@/components/AlbumOutline'
import StoryList from '@/components/StoryList'
import StoryList2 from '@/components/StoryList2'
import ListenCompre from '@/components/ListenCompre'
import ExpressWords from '@/components/ExpressWords'
import HomePage from '@/components/HomePage'
import HomePage2 from '@/components/HomePage2'
import CalendarPlan from '@/components/CalendarPlan'
import KnowledgeVideo from '@/components/KnowledgeVideo'
import GenduAssess2 from '@/components/GenduAssess2'
import ReportCard from '@/components/ReportCard'
import StoreVideo from '@/components/StoreVideo'
import Agreement from '@/components/PublicAgree'
import MarketShow from '@/components/MarketShow'
import MarketShow2 from '@/components/MarketShow2'
import ContentFlow from '@/components/ContentFlow'
import ContentDetail from '@/components/ContentDetail'
import AppDown from '@/components/AppDown'
import UserLogin from '@/components/UserLogin'
import MsgSend from '@/components/MsgSend'
import VideoChat from '@/components/VideoChat'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/outline',
      name: 'outline',
      component: AlbumOutline
    },
    {
      path: '/storylist',
      name: 'storylist',
      component: StoryList
    },
    {
      path: '/storylist2',
      name: 'storylist2',
      component: StoryList2
    },
    {
      path: '/listencompre',
      name: 'listencompre',
      component: ListenCompre
    },
    {
      path: '/expresswords',
      name: 'expresswords',
      component: ExpressWords
    },
    {
      path: '/homepage',
      name: 'homepage',
      component: HomePage
    },
    {
      path: '/',
      name: 'homepage2',
      component: HomePage2
    },
    {
      path: '/plan',
      name: 'plan',
      component: CalendarPlan
    },
    {
      path: '/video',
      name: 'video',
      component: KnowledgeVideo
    },
    {
      path: '/gendu2',
      name: 'gendu2',
      component: GenduAssess2
    },
    {
      path: '/reportcard',
      name: 'reportcard',
      component: ReportCard
    },
    {
      path: '/store',
      name: 'storevideo',
      component: StoreVideo
    },
    {
      path: '/videochat',
      name: 'videochat',
      component: VideoChat
    },
    {
      path: '/agreement',
      name: 'agreement',
      component: Agreement
    },
    {
      path: '/marketshow',
      name: 'marketshow',
      component: MarketShow
    },
    {
      path: '/marketshow2',
      name: 'marketshow2',
      component: MarketShow2
    },
    {
      path: '/contentflow',
      name: 'contentflow',
      component: ContentFlow
    },
    {
      path: '/contentdetail',
      name: 'contentdetail',
      component: ContentDetail
    },
    {
      path: '/appdown',
      name: 'appdown',
      component: AppDown
    },
    {
      path: '/login',
      name: 'login',
      component: UserLogin
    },
    {
      path: '/msgsend',
      name: 'msgsend',
      component: MsgSend
    },
  ],
  mode: "history"//干掉地址栏里边的#号键
})


<template>
  <div>
    <div v-if="type==0 || type==1" style="width:3.45rem; padding:0.15rem;color:#222222;font-size: 0.13rem;line-height: 0.2rem;" v-html="agreedoc"></div>
    <div v-if="type==2" style="padding:0.15rem;">
      <div style="color:#222222;font-size: 0.15rem;">通途英语APP学习内容具有自主版权，以下是部分样例，侵权必究，有任何问题请联系客服。</div>
      <img style="display: block;width:3.45rem;padding-top: 0.1rem;" src="https://tingting-1303694685.cos.ap-beijing.myqcloud.com/tingtingdata/agreement/copyright1.jpg">
      <img style="display: block;width:3.45rem;padding-top: 0.1rem;" src="https://tingting-1303694685.cos.ap-beijing.myqcloud.com/tingtingdata/agreement/copyright2.jpg">
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      agreedoc:'',
      type:-1
    };
  },
  created() {
    document.title = '通途英语'
    this.getRouterData()
  },
  methods: {
    async getRouterData() {
      console.log("route",window.location.href)
      this.type = this.$route.query.type
      if(this.type==0) {
        axios.get('https://tingting-1303694685.cos.ap-beijing.myqcloud.com/tingtingdata/agreement/通途英语隐私政策.html')
        .then((result) =>{
          this.agreedoc=result.data
        }).catch((error) =>{
          console.log(error)
        })
      }
      else if(this.type==1){
        axios.get('https://tingting-1303694685.cos.ap-beijing.myqcloud.com/tingtingdata/agreement/通途英语服务使用协议.html')
        .then((result) =>{
          this.agreedoc=result.data
        }).catch((error) =>{
          console.log(error)
        })
      }
    }
  }
};
</script>

<style scoped>
</style>

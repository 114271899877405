
<template>
  <div id="gendu2">
    <div class="horizontal" style="padding:0.2rem 0.3rem 0 0.3rem;align-items:center;justify-content: center;">
      <img style="display: block;width:0.12rem;height:0.2rem;" src="../assets/left_arrow2.png" @click="pre">
      <img class="coverimage" v-bind:src="cover_image_url" alt="">
      <img style="display: block;width:0.12rem;height:0.2rem;" src="../assets/right_arrow2.png" @click="next">
    </div>
    <div style="font-size:0.18rem;text-align: center;">
      <span style="color:#444444;font-weight:bold;">{{ curIdx+1 }}</span>
      <span style="color: #999999;"> / </span>
      <span style="color: #999999;">{{ sent_list.length }}</span>
    </div>
    <img style="margin:0.1rem auto;display: block;width: 1.2rem;" v-bind:src="text_mode[text_type]" @click="textModeChange">

    <div v-if="curIdx>=0" style="margin:0.1rem 0 2rem 0;background:rgb(250,250,250);min-height:4rem;position: relative;">
      <div v-if="curIdx>0 && sent_list[curIdx-1].assess_result.total_score<=1" style="position: absolute;top:0;left:0;bottom:0;right:0;background: rgba(0, 0, 0, 0.4);">
        <img style="display: block;width:0.2rem;height:0.2rem;margin:1.25rem auto;" src="../assets/redlock2.png">
      </div>
      <div style="color:#222222;font-size:0.18rem;text-align:center;padding:0.16rem;width:3.43rem;line-height: 0.23rem;">
        <div v-if="(text_type == 0 || sent_list[curIdx].new_result) && ref_list.length > 0"
          style="word-wrap:break-word;">
          <span v-for="(item, index) in ref_list" :key="index">
            <span v-if="score_list[index] >= 85" style="color:green;">{{ item }}&nbsp;</span>
            <span v-else-if="score_list[index] >= 65">{{ item }}&nbsp;</span>
            <span v-else style="color:red;">{{ item }}&nbsp;</span>
          </span>
        </div>
        <div v-else-if="text_type == 1">{{ sent_list[curIdx].text2 }}</div>
        <div v-else-if="text_type == 2"></div>
        <div v-else>{{ sent_list[curIdx].text_en }}</div>
      </div>
      <div style="color:#999999;font-size:0.14rem;text-align:center;padding:0.16rem;width:3.43rem;">
        {{ sent_list[curIdx].text_ch }}</div>
      <div v-if="showReportButton" class="seereport" @click="checkReporter">生成学习报告</div>
    </div>
    <div class="bottomarea">
      <div v-if="recordStatus == 0" class="scorearea">
        <transition name="fade">
          <div v-if="show_great==1" style="position: absolute;left:0.2rem;top:-0.3rem;">
            <img style="display: block;width: 1rem;height: 0.2rem;" src="../assets/great.png">
          </div>
        </transition>
        <div class="horizontal" style="width:0.8rem;">
          <div style="font-size:0.32rem;color:#FF6B54;padding-left:0.2rem;">
            <div v-if="total_score <= 1">--</div>
            <div v-else>{{ total_score }}</div>
          </div>
          <div style="font-size:0.12rem;padding-left:0.03rem;padding-top:0.18rem;color:#666666;">分</div>
        </div>
        <div style="width:0.01rem;height:0.32rem;background: #e2e2e2;margin-left:0.1rem;"></div>
        <div style="width:0.6rem;margin-left: 0.1rem;">
          <div style="font-size:0.16rem;color:#666666;font-weight: bold;">
            <div v-if="pron_score <= 0">--</div>
            <div v-else>{{ pron_score }}</div>
          </div>
          <div style="font-size:0.12rem;padding-top:0.03rem;color:#999999;">准确度</div>
        </div>
        <div style="width:0.6rem;margin-left: 0.1rem;">
          <div style="font-size:0.16rem;color:#666666;font-weight: bold;">
            <div v-if="cont_score <= 0">--</div>
            <div v-else>{{ cont_score }}</div>
          </div>
          <div style="font-size:0.12rem;padding-top:0.03rem;color:#999999;">流利度</div>
        </div>
        <div style="width:0.6rem;margin-left: 0.1rem;">
          <div style="font-size:0.16rem;color:#666666;font-weight: bold;">
            <div v-if="integrity_score <= 0">--</div>
            <div v-else>{{ integrity_score }}</div>
          </div>
          <div style="font-size:0.12rem;padding-top:0.03rem;color:#999999;">完整度</div>
        </div>
      </div>
      <div v-else class="scorearea">
        <div style="font-size:0.14rem;color:#666666;margin:auto;">{{ tips_word }}</div>
      </div>

      <div class="buttondisplay">
        <div style="margin:auto">
          <div style="background:rgba(153,153,153,0.08);width:0.5rem;height:0.5rem;border-radius: 0.25rem;"
            @click="play(1)">
            <img class="replaybutton" v-bind:src="playicon1" alt="">
          </div>
          <div style="margin-top:0.1rem;">播放原声</div>
        </div>
        <div style="margin:auto">
          <div style="background:#009FF8;width:0.7rem;height:0.7rem;border-radius: 0.35rem;" @touchstart='touchstart'
            @touchend='touchend'>
            <img class="recordbutton" v-bind:src="recordicon" alt="">
          </div>
          <div style="margin-top:0.1rem;">长按录音</div>
        </div>
        <div style="margin:auto">
          <div style="background:rgba(153,153,153,0.08);width:0.5rem;height:0.5rem;border-radius: 0.25rem;"
            @click="play(2)">
            <img class="replaybutton" v-bind:src="playicon2" alt="">
          </div>
          <div style="margin-top:0.1rem;">播放录音</div>
        </div>
      </div>
      <div style="padding-bottom:0.3rem;"></div>
    </div>
    
    <div class="mask" v-if="guide_wav!=null">
      <div style="width:3.4rem;min-height:1rem;background: white;border-radius: 0.2rem;">
        <div style="padding: 0.15rem 0.2rem;color: #222222;font-size: 0.15rem;text-align: left;line-height: 0.23rem;">
          <span style="font-weight: 500;font-size:0.18rem;">Question:<br></span>
          {{ guide_txt }}
        </div>
        <div class="horizontal">
          <div class="button2" @click="play(0)">播放问题</div>
          <div class="button2" @click="startGendu">跟读作答</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import axios from 'axios'
import { Howl } from 'howler'
import { wxConfig } from './util'
import wx from 'weixin-js-sdk'

export default {
  data() {
    return {
      userID: "",
      albumID: "",
      subAlbumID: "",
      storyID: "",

      os_type: "",

      cover_image_url: require('../assets/defaultcover.png'),
      sent_list: [],
      curIdx: -1,
      showReportButton: false,
      show_great:0,
      recordStatus: 0,
      tips_word: '',
      learn_second:0,

      text_type: 0,
      text_mode: [
        require('../assets/gendumode1.png'),
        require('../assets/gendumode2.png'),
        require('../assets/gendumode3.png')
      ],

      total_score: -1,
      pron_score: -1,
      cont_score: -1,
      integrity_score: -1,
      ref_list: [],
      score_list: [],

      guide_wav: null,
      guide_txt: '',

      playicon1: require('../assets/genduplay.png'),
      playicon2: require('../assets/relisten2.png'),

      recordicon: require('../assets/record.png'),

      sound: null,
      wxjdk: false
    };
  },
  created() {
    document.title = '通途英语'
    this.getRouterData()

    if (this.$root.wxConfigUrl != "") {
      this.wxjdk = true
      this.wxInjection()
    }
  },
  beforeDestroy() {
    this.sound && this.sound.stop()
  },

  mounted() {
    const that = this
    window.setAssessResult = that.setAssessResult
  },
  methods: {
    async getRouterData() {
      console.log("route", window.location.href)
      let params = JSON.parse(this.$route.query.params)
      this.userID = params.userID
      this.albumID = params.albumID
      this.storyID = params.storyID
      this.crossInfo = params.crossInfo
      if (window.location.href.includes('os_type=')) this.os_type = this.$route.query.os_type

      let request_data = {
        'type': 'gendu_data',
        'data': {
          'user_id': this.userID,
          'album_id': this.albumID,
          'story_id': this.storyID
        }
      }
      await axios.post('https://caixiaowen.com/tongtu', JSON.stringify(request_data))
        .then((result) => {
          console.log(result.data)
          this.cover_image_url = result.data.story_data.cover_image_url
          this.sent_list = result.data.gendu_data.sent_list

          if (Object.prototype.hasOwnProperty.call(result.data.gendu_data, 'guide_wav')){
            this.guide_wav = result.data.gendu_data.guide_wav
            this.guide_txt = result.data.gendu_data.guide_txt
          }

          for (var i = 0; i < this.sent_list.length; i++) {
            if (Object.prototype.hasOwnProperty.call(this.sent_list[i], 'question')) {
              this.sent_list[i].text2 = this.sent_list[i].question.text_show
            }
            else {
              this.sent_list[i].text2 = this.sent_list[i].text_en
            }
            this.sent_list[i].new_result = false
            let tidx = this.sent_list[i].text2.indexOf(":")
            if (tidx > 0) {
              let prechar = this.sent_list[i].text2.charAt(tidx - 1)
              if (isNaN(Number(prechar, 10))) {
                this.sent_list[i].text2 = this.sent_list[i].text2.substring(tidx + 1)
              }
            }
            tidx = this.sent_list[i].text_en.indexOf(":")
            if (tidx > 0) {
              let prechar = this.sent_list[i].text_en.charAt(tidx - 1)
              if (isNaN(Number(prechar, 10))) {
                this.sent_list[i].text_en = this.sent_list[i].text_en.substring(tidx + 1)
              }
            }

            this.sent_list[i].assess_num = 0
            this.sent_list[i].assess_result = {'total_score':0}
            let lst = result.data.history.assess_list
            for (var j = 0; j < lst.length; j++) {
              if (lst[j].no == i) this.sent_list[i].assess_result = lst[j]
            }
          }
          this.curIdx = 0
          /*
          while (this.curIdx < this.sent_list.length) {
            if (this.sent_list[this.curIdx].assess_result.total_score > 1) {
              this.curIdx += 1
            }
            else {
              break
            }
          }*/
          this.curIdx -= 1
          this.next()

          if (this.ref_list.length > 0) {
            this.text_type = 0
          } else {
            this.text_type = 1
          }
        }).catch((error) => {
          console.log(error)
        })

    },

    wxInjection() {
      var u = navigator.userAgent;
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      var the_url = window.location.href
      if (this.$root.wxConfigUrl != "" && isiOS) {
        the_url = this.$root.wxConfigUrl
      } else {
        this.$root.wxConfigUrl = the_url
      }
      var params2 = {
        record: true
      }
      wxConfig(the_url, params2)
    },

    pre() {
      if(this.curIdx<=0) return
      this.curIdx-=1

      this.total_score = -1
      this.pron_score = -1
      this.cont_score = -1
      this.integrity_score = -1
      this.ref_list = []
      this.score_list = []
      this.recordStatus = 0
      if (Object.prototype.hasOwnProperty.call(this.sent_list[this.curIdx].assess_result, 'total_score')) {
        this.total_score = this.sent_list[this.curIdx].assess_result.total_score
        this.pron_score = this.sent_list[this.curIdx].assess_result.pron_score
        this.cont_score = this.sent_list[this.curIdx].assess_result.cont_score
        this.integrity_score = this.sent_list[this.curIdx].assess_result.integrity_score

        if (Object.prototype.hasOwnProperty.call(this.sent_list[this.curIdx].assess_result, 'ref_list')) {
          this.ref_list = this.sent_list[this.curIdx].assess_result.ref_list
          this.score_list = this.sent_list[this.curIdx].assess_result.score_list
        }
      }

      this.showReportButton = false
    },
    next() {
      if (this.curIdx >= this.sent_list.length - 1) return
      this.curIdx+=1

      this.total_score = -1
      this.pron_score = -1
      this.cont_score = -1
      this.integrity_score = -1
      this.ref_list = []
      this.score_list = []
      this.recordStatus = 0
      if (Object.prototype.hasOwnProperty.call(this.sent_list[this.curIdx].assess_result, 'total_score')) {
        this.total_score = this.sent_list[this.curIdx].assess_result.total_score
        this.pron_score = this.sent_list[this.curIdx].assess_result.pron_score
        this.cont_score = this.sent_list[this.curIdx].assess_result.cont_score
        this.integrity_score = this.sent_list[this.curIdx].assess_result.integrity_score

        if (Object.prototype.hasOwnProperty.call(this.sent_list[this.curIdx].assess_result, 'ref_list')) {
          this.ref_list = this.sent_list[this.curIdx].assess_result.ref_list
          this.score_list = this.sent_list[this.curIdx].assess_result.score_list
        }
      }

      if (this.curIdx == this.sent_list.length - 1
        && this.sent_list[this.curIdx].assess_num > 0) this.showReportButton = true
    },
    textModeChange() {
      this.text_type = (this.text_type + 1) % 3
    },

    startGendu(){
      if (this.sound != null && this.sound.playing()) {
        this.sound.stop()
      }
      this.guide_wav = null
    },

    play(type) {
      if (this.sound != null && this.sound.playing()) {
        this.sound.stop()
      }
      var wav = null
      if (type == 0) {
        wav = this.guide_wav
      }
      if (type == 1) {
        wav = this.sent_list[this.curIdx].wav
      }
      if (type == 2) {
        if (Object.prototype.hasOwnProperty.call(this.sent_list[this.curIdx].assess_result, 'mp3url')) {
          wav = this.sent_list[this.curIdx].assess_result.mp3url
        }
        else {
          Toast("你还没有录音哦")
          return
        }
      }

      console.log("initial sound", wav)
      this.sound = new Howl({
        src: [wav],

        onplay: () => {
          console.log("开始播放啦")
          if (type == 1) this.playicon1 = require('../assets/gendustop.png')
          if (type == 2) this.playicon2 = require('../assets/gendustop.png')
          if (type == 0 || type == 1) {
            this.learn_second += this.sound.duration()
            console.log("learn_second "+this.learn_second)
          }
        },
        onstop: () => {
          console.log("停止播放啦")
          if (type == 1) this.playicon1 = require('../assets/genduplay.png')
          if (type == 2) this.playicon2 = require('../assets/relisten2.png')
        },
        onend: () => {
          console.log("结束播放啦")
          if (type == 1) this.playicon1 = require('../assets/genduplay.png')
          if (type == 2) this.playicon2 = require('../assets/relisten2.png')
        },
        onloaderror: () => {
          Toast("声音导入失败")
        },
        onplayerror: () => {
          Toast("声音播放失败")
        },
      })
      this.sound.play()
    },
    touchstart(e) {
      if(this.curIdx>0 && this.sent_list[this.curIdx-1].assess_result.total_score<=1){
        Toast("请按顺序练习哦")
        return
      }
      e.preventDefault()

      if (this.sound != null && this.sound.playing()) {
        this.sound.stop()
      }
      if (this.recordStatus == 2) {
        this.tips_word = '正在测评中...,无法录音'
        return
      }
      this.recordStatus = 1
      this.tips_word = '正在录音......'

      let params = JSON.stringify({
        start: true,
      })
      let json_param = {
        'params': params
      }
      if (this.os_type == "android") {
        window.android.speechAssess(JSON.stringify(json_param))
      }
      else if (this.os_type == "ios") {
        window.webkit.messageHandlers.speechAssess.postMessage(json_param)
      }
      else if (this.wxjdk) {
        let that=this
        wx.startRecord({
          success: function () {
            console.log("wx success start record")
          },
          fail: function () {
            Toast("启动录音失败")
            that.recordStatus = 0
          }
        });
      }
      else {
        this.recordStatus = 0
        Toast("不支持录音")
      }
    },
    touchend() {
      if (this.recordStatus == 2) this.tips_word = '正在测评中......'
      if (this.recordStatus != 1) return

      //Toast("结束录音")
      let params = JSON.stringify({
        start: false,
        no: this.curIdx,
        userID: this.userID,
        storyID: this.storyID,
        assess_ref: this.sent_list[this.curIdx].text_en,
        learn_second:this.learn_second
      })
      this.learn_second = 0
      this.recordStatus = 2
      this.tips_word = '正在测评中......'

      var tCurIdx = this.curIdx
      var tAssessNum = this.sent_list[this.curIdx].assess_num
      var that = this
      setTimeout(function () {
        if (that.recordStatus == 2
          && that.curIdx == tCurIdx
          && that.sent_list[that.curIdx].assess_num == tAssessNum) {
          that.recordStatus = -1
          that.tips_word = '系统超时......'
          that.sent_list[that.curIdx].assess_num += 1
        }
      }, 10000)

      let json_param = {
        'params': params
      }

      if (this.os_type == "android") {
        window.android.speechAssess(JSON.stringify(json_param))
      }
      else if (this.os_type == "ios") {
        window.webkit.messageHandlers.speechAssess.postMessage(json_param)
      }
      else if (this.wxjdk) {
        let that = this
        wx.stopRecord({
          success: function (res) {
            console.log("wx success stop record")
            that.processRecord(res.localId)
          },
          fail: function () {
            Toast('停止录音失败');
            that.recordStatus = 0
          }
        });
      }
      else {
        Toast("不支持录音")
      }
    },
    setAssessResult(jsondata) {
      if (this.recordStatus != 2) return

      this.recordStatus = 0

      jsondata = jsondata.replace("‘","'")

      let t = JSON.parse(jsondata)
      if (!Object.prototype.hasOwnProperty.call(t, 'asr_content')) {
        return
      }

      this.sent_list[this.curIdx].new_result = true
      this.sent_list[this.curIdx].assess_result.ret = t
      this.sent_list[this.curIdx].assess_result.total_score = t.asr_content.total_score
      this.sent_list[this.curIdx].assess_result.pron_score = t.asr_content.pron_score
      this.sent_list[this.curIdx].assess_result.cont_score = t.asr_content.cont_score
      this.sent_list[this.curIdx].assess_result.integrity_score = t.asr_content.integrity
      this.sent_list[this.curIdx].assess_result.mp3url = t.mp3url

      this.total_score = t.asr_content.total_score
      this.pron_score = t.asr_content.pron_score
      this.cont_score = t.asr_content.cont_score
      this.integrity_score = t.asr_content.integrity
      this.ref_list = t.ref_list
      this.score_list = t.score_list

      if(this.total_score>1) {
        this.sent_list[this.curIdx].assess_num += 1
        if (this.curIdx == this.sent_list.length - 1) this.showReportButton = true
      }

      if (this.total_score >= 85) {
        this.show_great=1
        let voicemp3=require('../assets/great.mp3')
        new Howl({src: [voicemp3],}).play()
        var that = this
        setTimeout(function () {
          that.show_great=0
          that.next()
        }, 2500)
      }
    },
    
    checkReporter() {
      this.sound && this.sound.stop()

      let params = JSON.stringify({
        userID: this.userID,
        albumID: this.albumID,
        storyID: this.storyID,
        crossInfo: this.crossInfo,
        update: true,
        from_type : this.albumID=="S00G"?3:0
      })
      let json_param = {
        'url_param': "reportcard?params=" + params + '&os_type=' + this.os_type,
        'title': ""
      }

      if (this.os_type == "android") {
        window.android.gotoDetail(JSON.stringify(json_param))
      }
      else if (this.os_type == "ios") {
        window.webkit.messageHandlers.gotoDetail.postMessage(json_param)
      }
      else {
        this.$router.push({
          name: 'reportcard',
          query: { params }
        })
      }
    },
    processRecord(audiolocalId) {
      let that = this
      wx.uploadVoice({
        localId: audiolocalId,
        isShowProgressTips: 1,
        success: function (res) {
          console.log("success get weixin serverId", res.serverId)

          //
          let sid = that.userID + '__' + Date.parse(new Date())
          let request_data = {
            'type': 'speech_assess',
            'data': {
              'user_id': that.userID,
              'story_id': that.storyID,
              'no': that.curIdx,
              'sid': sid,
              'assess_ref': that.sent_list[that.curIdx].text_en,

              'action_type': '15',
              'idx': '-1',
              'wx_audio_serverid': res.serverId
            }
          }
          axios.post('https://caixiaowen.com/tongtu', JSON.stringify(request_data), { timeout: 15000 }
          ).then((result) => {
            //console.log("assess", result)
            that.setAssessResult(JSON.stringify(result['data']))
          }).catch((error) => {
            console.log(error)
            that.setAssessResult("{}")
          })
        },
        fail: function () {
          Toast("微信上传失败")
          //that.setAssessResult("{}")
          that.recordStatus = 0
        }
      });
    },
  }
};
</script>

<style scoped>
#gendu2 {
  min-height: 100vh;
  height: auto;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

#gendu2 .coverimage {
  display: block;
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  margin: 0.1rem auto;
  animation: rotateImg 10s linear infinite;
}

@keyframes rotateImg {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#gendu2 .horizontal {
  flex-direction: row;
  display: flex;
  position: relative;
}

#gendu2 .bottomarea {
  position: fixed;
  width: 100%;
  bottom: 0rem;
  background: white;
  padding-top:0.05rem;
}

#gendu2 .seereport {
  background: #009FF8;
  margin-left: 0.88rem;
  margin-bottom: 0.18rem;
  border-radius: 0.1rem;
  width: 2rem;
  height: 0.3rem;

  font-size: 0.14rem;
  color: #FFFFFF;
  font-weight: bold;
  text-align: center;
  padding-top: 0.15rem;
}

#gendu2 .scorearea {
  flex-direction: row;
  display: flex;
  position: relative;

  background: rgba(153, 153, 153, 0.08);
  margin-left: 0.38rem;
  margin-bottom: 0.1rem;
  border-radius: 0.1rem;
  align-items: center;
  text-align: center;

  width: 3rem;
  height: 0.62rem;
}

#gendu2 .buttondisplay {
  flex-direction: row;
  display: flex;
  position: relative;

  align-items: center;
  justify-content: center;
  margin-left: 0.35rem;
  width: 3.05rem;

  color: #999999;
  font-size: 0.12rem;
  text-align: center;
}

#gendu2 .replaybutton {
  display: block;
  width: 0.16rem;
  height: 0.16rem;
  padding: 0.17rem;
}

#gendu2 .recordbutton {
  display: block;
  width: 0.2rem;
  height: 0.3rem;
  padding: 0.2rem 0.25rem;
  pointer-events: none;
}

#gendu2 .mask{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.7);
  z-index:9999;

  width: 100%;
  height: 100%vh;

  display: flex;
  align-items: center;
  justify-content: center;
}
#gendu2 .button2 {
  margin: 0.1rem auto;
  border-radius: 0.1rem;
  border: solid #666666 0.01rem;
  padding: 0.1rem;
  text-align: center;
  color: #222222;
  font-size: 0.16rem;
}

</style>

<template>
  <div class="" id="homepage2">
    <div class="learntxt" >学习</div>
    <div class="topinfo">
      <div style="width:0.8rem; color:white;font-size: 0.12rem;padding-left: 0.2rem;">
        <div style="font-size: 0.14rem;padding-top:0.35rem;font-weight:350;">今日学习</div>
        <div style="padding-top: 0.06rem;">
          <span style="font-size:0.25rem; font-weight: bolder;">{{ today_minute }}</span>
          <span style="font-size:0.12rem;"> 分钟</span>
        </div>
      </div>
      <div style="width:1.0rem; color:white;font-size: 0.12rem;padding-left: 0.2rem;">
        <div style="font-size: 0.14rem;padding-top:0.35rem;font-weight:350;">累计学习</div>
        <div style="padding-top: 0.06rem;">
          <span style="font-size:0.25rem; font-weight: bolder;">{{ acc_minute }}</span>
          <span style="font-size:0.12rem;"> 分钟</span>
        </div>
      </div>
      <div style="padding:0.35rem 0 0 0.2rem;color:white;font-size: 0.12rem;">
        <div class="horizontal" @click="gotoPlan()">
          <div style="font-size: 0.14rem;font-weight:350;">学习日历</div>
          <img style="padding-left:0.04rem;display:block; width:0.1rem;height:0.15rem;" src="../assets/right_arrow.png">
        </div>
      </div>
    </div>
    
    <div style="background: #F7F7F7;margin-top:0.3rem;">
      <div class="horizontal" style="padding-top:0.1rem;align-items: flex-end;">
        <div style="padding-left: 0.2rem;font-size: 0.2rem;font-weight: bolder;color: #444444;">热门</div>
        <div style="padding-left: 2.4rem;font-size: 0.12rem;font-weight: bolder;color: #666666;" @click="load(1)">换一批</div>
        <img style="padding-left: 0.04rem;display: block;width: 0.13rem;height: 0.13rem;" src="../assets/relisten4.png">
      </div>
      <div v-if="sub_album_list.length>0" class="horizontal" style="margin-top:0.15rem;" @click="gotoSubAlbum(0,0)">
        <img class="coverimage2" v-bind:src="sub_album_list[0][0].cover_image_url" alt="">
        <div>
          <div class="title" style="width:1.8rem;padding-top: 0.1rem;font-weight: 500;">{{ sub_album_list[0][0].title_en }}</div>
          <div class="title" style="width:1.8rem;padding-top: 0.05rem;font-size:0.12rem;-webkit-line-clamp: 2;line-clamp: 2;">
            {{ sub_album_list[0][0].brief_info}}
          </div>
          <div class="title" style="width:1.8rem;padding-top: 0.25rem;font-size:0.12rem;color:gray;">{{level2text[sub_album_list[0][0].level-1]}}</div>
        </div>
      </div>
      <div class="subalbumlist" style="flex-wrap: nowrap;overflow: auto;margin-right: 0.2rem;">
        <div v-for="(item,index) in sub_album_list[0]" :key="index">
          <div v-if="index>0" style="position: relative;">
            <img class="coverimage" style="width: 0.9rem;height:0.9rem;" v-bind:src="item.cover_image_url2" alt="" @click="gotoSubAlbum(0,index)">
            <div class="title" style="margin-top:0.1rem;width:0.85rem;">{{item.title}}</div>
          </div>
        </div>
      </div>
    </div>

    <div v-for="(index) in [1,2,3,4]" :key="index">
      <div style="padding-top:0.3rem;padding-left: 0.2rem;font-size: 0.20rem;color: #444444;font-weight: bolder;">
        <div v-if="index==1">精选主题课</div>
        <div v-if="index==2">口语练习课</div>
        <div v-if="index==3">听力练习课</div>
        <div v-if="index==4" class="horizontal" style="align-items: flex-end;">
          <div>轻松听</div>
          <div style="margin-left: 2.1rem;font-size: 0.12rem;color: #666666;" @click="gotoAlbum(index)">查看更多</div>
          <img style="padding-left:0.04rem;display: block;width: 0.09rem;height: 0.14rem;" src="../assets/right_arrow3.png">
        </div>
      </div>
      <div class="subalbumlist">
        <div v-for="(item2,index2) in sub_album_list[index]" :key="index2">
          <div style="position: relative;">
            <img class="coverimage" style="width:1.6rem;height:1.2rem;" v-bind:src="item2.cover_image_url" alt="" @click="gotoSubAlbum(index,index2)">
            <div style="position: absolute;top: 0.05rem;left: 0.2rem;background: rgba(0, 0, 0, 0.5);border-radius: 0.05rem;">
              <div class="horizontal" style="padding:0.02rem;align-items: center;">
                <img v-if="index==2" style="display: block; width:0.1rem; height:0.12rem;" src="../assets/tips_maikefeng.png">
                <img v-if="index==3" style="display: block; width:0.12rem; height:0.12rem;" src="../assets/tips_erji.png">
                <div style="padding-left:0.02rem;font-size:0.1rem;color:white;font-weight: 300;">
                  <span v-if="index==2">跟读</span>
                  <span v-if="index==3">听力</span>
                </div>
              </div>
            </div>
            <div v-if="index!=4" style="position: absolute;bottom: 0.05rem;left: 0.2rem;">
              <!--
              <div class="horizontal">
                <div style="width:0.25rem;height:0.02rem;background: white;"></div>
                <div style="width:0.25rem;height:0.02rem;background: gray;"></div>
              </div>
              -->
              <div style="font-size:0.11rem;color:white;font-weight: 350;">{{ item2.progress_txt }}</div>
            </div>
          </div>
          <div class="title" style="margin-top:0.1rem;width:1.55rem;">
            <span v-if = "index==1"> {{item2.title_en}} </span>
            <span v-else>{{item2.title}}</span>
          </div>
          <div class="title" style="margin-top:0.05rem;font-size: 0.11rem;color:gray;width:1.55rem;padding-bottom: 0.05rem;">
            <span v-if = "index==1"> {{item2.labels}} </span>
            {{level2text[item2.level-1]}}
          </div>
        </div>
        <div v-if="index!=4" class="coverimage" style="width:1.6rem;height:1.2rem;background: #cccccc" @click="gotoAlbum(index)">
          <img style="display:block;width:0.3rem;height: 0.3rem;padding:0.3rem 0 0.08rem 0.65rem;" src="../assets/add_pic.png">
          <div style="text-align: center;font-size: 0.13rem;color:white;">添加课程</div>
        </div>
      </div>
    </div>
    <div v-if="sub_album_list.length>0" style="text-align: center;font-size:0.12rem;padding:0.2rem;color:#dddddd;">到底啦!</div>
	</div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      userID:"",

      os_type:"",
      loading:true,
      stage:0,

      user_prefer:"",

      today_minute:'--',
      acc_minute:'--',

      level2text:["零基础","初级","中级","高级","专业级"],

      sub_album_list : []
    }
  },

  methods : {
    gotoAlbum(index) {
      var albumID="S00E"
      if(index==2){
        albumID="S00G"
      }
      else if(index==3){
        albumID="S00I"
      }
      else if(index==4){
        albumID="S00D"
      }

      var params = {
        userID:this.userID,
        albumID:albumID,
        stage:this.stage
      }
      params = JSON.stringify(params)

      let json_param = {
        'url_param':"outline?params=" + params + '&os_type=' + this.os_type,
        'title':""
      }

      if(this.os_type=="android"){
        window.android.gotoDetail(JSON.stringify(json_param))
      }
      else if (this.os_type=="ios"){
        window.webkit.messageHandlers.gotoDetail.postMessage(json_param)
      }
      else{
        this.$router.push({
          name: 'outline',
          query: {params}
        })
      }
    },
    gotoSubAlbum(index,index2) {
      var subAlbumID = this.sub_album_list[index][index2].album_id
      var albumID=subAlbumID.substr(0,4)
      var pageName="storylist"

      if(albumID=="S00G" || albumID=="S00I"){
        pageName="storylist2"
      }
      else if(albumID=="S00D"){
        pageName="contentflow"
      }

      let params = JSON.stringify({
        userID:this.userID,
        albumID:albumID,
        subAlbumID:subAlbumID,
        stage:this.stage
      });
      let json_param = {
        'url_param':pageName+"?params=" + params + '&os_type=' + this.os_type,
        'title':"故事列表"
      }
      if(this.os_type=="android"){
        window.android.gotoDetail(JSON.stringify(json_param))
      }
      else if(this.os_type=="ios"){
        window.webkit.messageHandlers.gotoDetail.postMessage(json_param)
      }
      else{
        this.$router.push({
          name: pageName,
          query: { params }
        })
      }
    },
    gotoPlan(){
      let json_param = {
        'url_param':"plan?userID=" + this.userID + '&os_type=' + this.os_type,
        'title':"学习日历"
      }

      if(this.os_type=="android"){
        window.android.gotoDetail(JSON.stringify(json_param))
      }
      else if (this.os_type=="ios"){
        window.webkit.messageHandlers.gotoDetail.postMessage(json_param)
      }
      else{
        this.$router.push({
          name: 'plan',
          query: {
            'userID':this.userID
          }
        })
      }
    },
    refresh(){
      console.log('refresh')
      this.load(2)
    },
    async load(page_type){
      if(page_type==1 && this.sub_album_list.length==0) return

      let request_data = {
        'type' : 'user_sub_album_list',
        'data' : {
          'user_id':this.userID,
          'user_prefer':this.user_prefer,
          'page_type':page_type
        }
      }
      await axios.post('https://caixiaowen.com/tongtu',JSON.stringify(request_data))
      .then((result) =>{
        console.log(result.data)
        if(page_type==1){
          var t = this.sub_album_list.slice()
          t[0] = result.data.sub_album_list[0]
          this.sub_album_list = t
        } else if(page_type==2){
          let t0 = this.sub_album_list[0]
          this.sub_album_list = result.data.sub_album_list
          this.sub_album_list[0] = t0
        }else {
          this.sub_album_list = result.data.sub_album_list
          this.stage = result.data.stage
        }
        this.today_minute = "" + parseInt((parseInt(result.data.today_time)+30)/60)
        this.acc_minute = "" + parseInt((parseInt(result.data.total_time)+30)/60)
      }).catch((error) =>{
        console.log(error)
      })
      this.loading = false
    },

    /*
    handleScroll(){
      var topScroll = document.documentElement.scrollTop
      var nav = document.getElementById("learntxt");
      if (topScroll > 120) {
        nav.style.position = 'fixed'
        nav.style.width='100%'
        nav.style.top = '0'
        nav.style.paddingTop = '0.1rem'
        nav.style.paddingBottom = '0.1rem'
        nav.style.zIndex = '999'
        nav.style.backgroundColor = 'rgba(255,255,255,0.98)'
      } else {
        nav.style = ''
        nav.style.padding = '0.2rem'
        nav.style.fontSize = '0.28rem'
        nav.style.fontWeight = 'bolder'
        nav.style.color = '#222222'
      }
    },*/
  },

  async created() {
    document.title = '通途英语'
    console.log("route",window.location.href)
    this.userID = this.$route.query.userID
    if(window.location.href.includes('os_type=')) this.os_type=this.$route.query.os_type
    if(window.location.href.includes('user_prefer=')) this.user_prefer=this.$route.query.user_prefer

    this.load(0)
  },
  mounted(){
    const that = this
    window.refresh = that.refresh
  },
}
</script>

<style scoped>

#homepage2 {
  min-height: 100vh;
  height:auto;
}

#homepage2 .learntxt {
  position :fixed;
  width: 100%;
  top:0;
  padding: 0.1rem 0.2rem;
  z-index: 999;
  background: rgba(255,255,255,0.98);
  font-size: 0.28rem;
  font-weight: bolder;
  color: "#222222";
}

#homepage2 .topinfo {
  flex-direction: row;
  display: flex;
  position: relative;

  background: url('../assets/homepage_background.png');
  background-size: cover;
  margin-left: 0.2rem;
  width:3.35rem;
  height:1.2rem;
  margin-top:0.6rem;
}

#homepage2 .horizontal {
  flex-direction: row;
  display: flex;
  position: relative;
}

#homepage2 .subalbumlist {
  flex-direction: row;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  padding-bottom: 0.15rem;
  margin-left:0.05rem;
}

#homepage2 .coverimage{
  display: block;
  width: 1.05rem;
  height: 0.75rem;
  border-radius:0.05rem;
  margin-left:0.15rem;
  margin-top:0.15rem;
}
#homepage2 .coverimage2{
  display:block;
  width:1.4rem;
  height:1.05rem;
  border-radius:0.08rem;
  margin:0 0 0.15rem 0.2rem;
}

#homepage2 .title {
  margin-left:0.15rem;
  font-size: 0.14rem;
  width:0.8rem;
  color:#000000;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden; 
  text-overflow: ellipsis; 
  display: -webkit-box; 
  -webkit-line-clamp: 1;
  line-clamp: 1; 
  -webkit-box-orient: vertical; 
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;height: 0;
}
</style>

<template>
  <div id="marketshow2" style="position: relative;">
    <div v-if="saleType<100">
      <img style="display:block;width:100%;" src="../assets/sales1/pic10.jpg" alt="">
    </div>
    <div v-else>
      <img style="display:block;width:100%;" src="../assets/sales2/pic1.jpg" alt="">
      <img style="display:block;width:100%;" src="../assets/sales2/pic2.jpg" alt="">
      <img style="display:block;width:100%;" src="../assets/sales2/pic3.jpg" alt="">
      <img style="display:block;width:100%;" src="../assets/sales2/pic4.jpg" alt="">
      <img style="display:block;width:100%;" src="../assets/sales2/pic5.jpg" alt="">
      <img style="display:block;width:100%;" src="../assets/sales2/pic6.jpg" alt="">
      <img style="display:block;width:100%;" src="../assets/sales2/pic7.jpg" alt="">
      <img style="display:block;width:100%;" src="../assets/sales2/pic8.jpg" alt="">
      <img style="display:block;width:100%;" src="../assets/sales2/pic11.jpg" alt="">
    </div>
    <div style="position: absolute;left: 0.4rem;right:0.4rem;bottom: 0.4rem;background: rgba(247, 247, 247, 1.0);border-radius: 0.1rem;">
      <div style="font-size: 0.18rem;margin:0.2rem;">
        <input ref="phone_ref" type="number" style="border-style: dotted;padding: 0.1rem;width:2.35rem;height: 0.22rem;" placeholder="请输入手机号">
      </div>
      <div class="paybutton1" @click="phone_register">
        <div style="font-size: 0.18rem;color: white;text-align: center;padding: 0.08rem 0;">立即领取</div>
      </div>
      <div style="font-size: 0.12rem;color: gray;margin: 0rem 0.2rem 0.1rem 0.2rem;">
        温馨提示: 输入手机号后点击领取，会自动获得{{ duration }}天通途英语App会员资格
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { Toast } from 'vant';
import { wxConfig } from './util';

export default {
  data() {
    return {
      saleType: 0,
      duration: 7,
      waiting:false
    };
  },
  created() {
    document.title = '通途英语'
    this.getRouterData()
  },
  methods: {
    getRouterData() {
      console.log("route", window.location.href)
      this.saleType = this.$route.query.type
      this.duration = this.$route.query.duration

      var shareTitle = "通途英语"
      var shareDesc = ""
      if(this.saleType==107){
        shareDesc = "熊叔推荐的英语学习APP，来免费领取"+this.duration+"天课程吧"
      }
      else if(this.saleType==108){
        shareDesc = "河北农业大学专属福利，来免费领取"+this.duration+"天课程吧"
      }
      else if(this.saleType==102){
        shareTitle = "通途英语APP免费"+this.duration+"天会员" 
        shareDesc = "快速提升英语听说"
      }
      if(shareDesc.length>0){
        var the_url = window.location.href
        var params2 = {
          shareLink: the_url,
          shareTitle: shareTitle,
          shareDesc: shareDesc,
          share: true
        }
        wxConfig(the_url,params2)
      }
    },

    async phone_register(){
      if(this.waiting) return
      this.waiting = true

      let phonenum = this.$refs.phone_ref.value
      if(phonenum.length!=11){
        Toast("手机号码无效")
        this.waiting = false
        return
      }

      let request_data = {
        'type': 'phone_register',
        'data': {
          'phone_number': phonenum,
          'sale_type': '' + this.saleType,
          'duration': '' + this.duration
        }
      }
      await axios.post('https://caixiaowen.com/tongtu', JSON.stringify(request_data))
        .then((result) => {
          console.log(result.data)
          this.waiting = false
          Toast(result.data.err_info)
          if(result.data.err_no==0){
            var that = this
            setTimeout(function () {
              that.$router.push({
                name: 'appdown',
                query: {}
              })
            }, 1500)
          }
        }).catch((error) =>{
          console.log(error)
          this.waiting = false
        })
    },
  }
};
</script>

<style scoped>
#marketshow2 .paybutton1 {
  background: url("../assets/sales1/pay_icon.png");
  background-size: cover;
  margin:0.1rem 0.2rem;
  width: 2.55rem;
  height: 0.37rem;
}
</style>
